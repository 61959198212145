import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { AIHero } from "@/components/common/ai-hero";
import { MainNav } from "@/components/common/main-nav";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import { useTheme } from "@/components/common/theme-provider";

import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/shadcn";
import "@blocknote/shadcn/style.css";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Persona } from "@/types";

import { Loader2Icon } from "lucide-react";

import { fetchWithProgress } from "@/hooks/useFetchWithProgress";

const SinglePersona: React.FC = () => {
  const { persona_id } = useParams<{ persona_id: string }>();
  const navigate = useNavigate();
  const [persona, setPersona] = useState<Persona | null>(null);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const editor = useCreateBlockNote();
  const { theme } = useTheme();

  const fetchPersona = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchWithProgress(
        `/api/v1/me/personas/${persona_id}`,
        {},
        navigate
      );

      if (response) {
        const data = await response.json();
        setPersona(data);
        setName(data.name);
        const blocksFromMarkdown = await editor.tryParseMarkdownToBlocks(
          data.content
        );
        editor.replaceBlocks(editor.document, blocksFromMarkdown);
      }
    } catch (error: any) {
      console.error("Failed to load the persona:", error);
    } finally {
      setIsLoading(false);
    }
  }, [persona_id, editor, navigate]);

  useEffect(() => {
    fetchPersona();
  }, [fetchPersona]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const content = (
        await editor.blocksToMarkdownLossy(editor.document)
      ).trim();
      const updatedPersona = {
        ...persona,
        name,
        content,
      };

      console.log("updatedPersona:", updatedPersona);

      const response = await fetchWithProgress(
        `/api/v1/me/personas/${persona_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedPersona),
        },
        navigate
      );

      if (response) {
        const data = await response.json();
        setPersona(data);
      }
    } catch (error: any) {
      console.error("Failed to save the persona:", error);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <div className="flex flex-row ">
            <Loader2Icon className="mr-4 animate-spin" /> Loading...
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <MainNav />
        </nav>
        <ResponsiveNav />
        <div>
          <UserNav />
        </div>
      </header>

      <main className="container space-y-7 p-6">
        <div className="space-y-4 mb-6">
          <Input
            type="text"
            value={name}
            onChange={(e) =>
              setName((e.currentTarget as HTMLInputElement).value)
            }
            placeholder="Persona Name"
            className="w-full text-2xl border-none shadow-none font-semibold"
          />
        </div>
        <div className="rounded-lg overflow-hidden">
          <BlockNoteView
            theme={theme === "system" ? undefined : theme}
            editor={editor}
          />
        </div>
        <div className="mt-6">
          <Button onClick={handleSave} disabled={isSaving}>
            {isSaving ? "Saving..." : "Save Changes"}
          </Button>
        </div>
      </main>
    </>
  );
};

export default SinglePersona;
