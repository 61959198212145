import { Skeleton } from "@/components/ui/skeleton";

const TableSkeleton = () => {
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center space-x-3">
        <Skeleton className="h-4 w-4 bg-gray-200" />

        <Skeleton className="h-4 w-[15%] bg-gray-200" />

        <Skeleton className="h-4 w-[30%] bg-gray-200" />

        <Skeleton className="h-4 w-[30%] bg-gray-200" />

        <Skeleton className="h-4 w-[30%] bg-gray-200" />
      </div>
      <div className="flex justify-between items-center space-x-3">
        <Skeleton className="h-4 w-4 bg-gray-200" />

        <Skeleton className="h-4 w-[15%] bg-gray-200" />

        <Skeleton className="h-4 w-[30%] bg-gray-200" />

        <Skeleton className="h-4 w-[30%] bg-gray-200" />

        <Skeleton className="h-4 w-[30%] bg-gray-200" />
      </div>
      <div className="flex justify-between items-center space-x-3">
        <Skeleton className="h-4 w-4 bg-gray-200" />

        <Skeleton className="h-4 w-[15%] bg-gray-200" />

        <Skeleton className="h-4 w-[30%] bg-gray-200" />

        <Skeleton className="h-4 w-[30%] bg-gray-200" />

        <Skeleton className="h-4 w-[30%] bg-gray-200" />
      </div>
      <div className="flex justify-between items-center space-x-3">
        <Skeleton className="h-4 w-4 bg-gray-200" />

        <Skeleton className="h-4 w-[15%] bg-gray-200" />

        <Skeleton className="h-4 w-[30%] bg-gray-200" />

        <Skeleton className="h-4 w-[30%] bg-gray-200" />

        <Skeleton className="h-4 w-[30%] bg-gray-200" />
      </div>
    </div>
  );
};

export default TableSkeleton;
