import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { ApiKey } from "@/types";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";

import { TrashIcon } from "lucide-react";

import { useToast } from "@/components/ui/use-toast";

import { fetchWithProgress } from "@/hooks/useFetchWithProgress";

export const API = () => {
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [isFetchingApiKeys, setIsFetchingApiKeys] = useState(false);
  const [isCreatingNewApiKey, setIsCreatingNewApiKey] = useState(false);
  const project_id = localStorage.getItem("project_id");
  const navigate = useNavigate();

  const { toast } = useToast();

  const [toBeDeleted, setToBeDeleted] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = async () => {
    setIsFetchingApiKeys(true);
    try {
      const response = await fetchWithProgress(
        "/api/v1/me/api_keys",
        {
          method: "GET",
        },
        navigate
      );

      if (response.ok) {
        const data = await response.json();
        setApiKeys(data);
      }
    } catch (error: any) {
      console.error("Failed to load the API keys:", error);
    } finally {
      setIsFetchingApiKeys(false);
    }
  };

  const createNewApiKey = async () => {
    setIsCreatingNewApiKey(true);
    try {
      const response = await fetchWithProgress(
        "/api/v1/me/api_keys",
        {
          method: "POST",
          body: JSON.stringify({ name: "My New API Key" }),
        },
        navigate
      );

      if (response.ok) {
        fetchApiKeys();
      }
    } catch (error: any) {
      console.error("Failed to create new API key:", error);
    } finally {
      setIsCreatingNewApiKey(false);
    }
  };

  const openConfirmDeleteDialog = (toBeDeleted: string) => {
    setToBeDeleted(toBeDeleted);
    setIsOpen(true);
  };

  const deleteApiKey = async (apiKeyId: string) => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/me/api_keys/${apiKeyId}`,
        {
          method: "DELETE",
        },
        navigate
      );

      if (response.ok) {
        setIsOpen(false);
        fetchApiKeys();
        toast({
          description: (
            <div className="flex flex-row items-center">
              <TrashIcon className="mr-3 h-4 w-4" />
              API Key deleted.
            </div>
          ),
        });
      }
    } catch (error: any) {
      toast({
        description: (
          <div className="flex flex-row items-center">
            <TrashIcon className="mr-3 h-4 w-4" />
            {error.message}
          </div>
        ),
      });
      console.error("Failed to delete API key:", error);
    }
  };

  const toggleReveal = (index: number) => {
    setApiKeys(
      apiKeys.map((key: ApiKey, i: number) =>
        i === index ? { ...key, reveal: !key.reveal } : key
      ) as never[]
    );
  };

  return (
    <>
      <Card className="shadow-none border-none">
        <CardHeader>
          <CardTitle>API Keys</CardTitle>
          <CardDescription>Manage your API keys.</CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow className="flex justify-between">
                <TableHead>Name</TableHead>
                <TableHead>API Key</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isFetchingApiKeys ? (
                <div className="w-full flex flex-col mt-5">
                  <div className="animate-pulse h-5 bg-gray-300 rounded w-full my-5"></div>
                </div>
              ) : isCreatingNewApiKey ? (
                <div className="w-full flex flex-col mt-5">
                  <div className="animate-pulse h-5 bg-gray-300 rounded w-full my-5"></div>
                </div>
              ) : apiKeys.length > 0 ? (
                apiKeys.map((apiKey, index) => (
                  <TableRow key={index} className="flex justify-between">
                    <TableCell>{apiKey.name}</TableCell>
                    <TableCell>
                      {apiKey.reveal ? (
                        apiKey.api_key
                      ) : (
                        <span onClick={() => toggleReveal(index)}>
                          {apiKey.api_key.substring(0, 5) +
                            "*******************"}
                        </span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => openConfirmDeleteDialog(apiKey.api_key)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow className="flex justify-between">
                  <TableCell>No API Keys, you need to create a key.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <h2 className="mt-5">To get started:</h2>
          <ol>
            <li className="mt-5">
              <p>First, install the AI Hero API</p>
              <pre className="mt-2 p-2 bg-gray-100">
                <code className="text-xs">pip install aihero==0.4</code>
              </pre>
            </li>
            <li className="mt-5">
              <p>
                Set the following environment variables or have an `.env` file
                that can be loaded by the client.
              </p>
              <pre className="mt-2 p-2 bg-gray-100">
                <code className="text-xs">AI_HERO_PROJECT_ID={project_id}</code>
                <br />
                <code className="text-xs">
                  AI_HERO_API_KEY=&lt;Insert your api key here&gt;
                </code>
              </pre>
            </li>
            <li className="mt-5">
              <p>Create the project instance.</p>
              <pre className="mt-2 p-2 bg-gray-100">
                <code className="text-xs">
                  from aihero.projects import Projects
                </code>
                <br />
                <code className="text-xs">print(Projects.get())</code>
              </pre>
            </li>
          </ol>
        </CardContent>
        <CardFooter>
          <Button onClick={createNewApiKey} disabled={isCreatingNewApiKey}>
            Create New API Key
          </Button>
        </CardFooter>
      </Card>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure you want to proceed?</DialogTitle>
            <DialogDescription>This action cannot be undone.</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="destructive"
              type="button"
              onClick={() => deleteApiKey(toBeDeleted)}
            >
              Confirm
            </Button>
            <Button
              variant="outline"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
