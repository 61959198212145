import React, { useEffect } from "react";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/shadcn";
import { useTheme } from "@/components/common/theme-provider";
import "@blocknote/core/style.css";

interface MarkdownContentProps {
  markdown: string;
}

const MarkdownContent: React.FC<MarkdownContentProps> = ({ markdown }) => {
  const editor = useCreateBlockNote();
  const { theme } = useTheme();

  useEffect(() => {
    const updateEditorContent = async () => {
      if (editor && markdown) {
        const blocksFromMarkdown = await editor.tryParseMarkdownToBlocks(
          markdown
        );
        editor.replaceBlocks(editor.document, blocksFromMarkdown);
      }
    };

    updateEditorContent();
  }, [editor, markdown]);

  return (
    <div className="markdown-content">
      {editor && (
        <BlockNoteView
          editor={editor}
          theme={theme === "system" ? undefined : theme}
          editable={false}
        />
      )}
    </div>
  );
};

export default MarkdownContent;
