import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@/components/common/theme-provider";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { marked } from "marked";
import {
  SparklesIcon,
  PlayIcon,
  XIcon,
  TrashIcon,
  BoltIcon,
} from "lucide-react";
import { InstructionStep, ModeEnum, Variables } from "@/types";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/shadcn";
import "@blocknote/shadcn/style.css";

interface InstructionCellProps {
  step: InstructionStep;
  updateStep: (updatedStep: InstructionStep) => void;
  deleteStep: (originalStep: InstructionStep) => void;
  variables: Variables;
}

marked.use({
  gfm: true,
  breaks: true,
});

export const InstructionCell: React.FC<InstructionCellProps> = ({
  step,
  updateStep,
  deleteStep,
  variables,
}) => {
  const [localStep, setLocalStep] = useState<InstructionStep>(step);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const editor = useCreateBlockNote();

  useEffect(() => {
    setLocalStep(step);
  }, [step]);

  useEffect(() => {
    if (localStep.mode === ModeEnum.EDITING) {
      if (textareaRef.current) {
        textareaRef.current.focus();
        autoGrow(textareaRef.current);
      }
    }
  }, [localStep.mode, localStep.step_id]);

  useEffect(() => {
    const updateEditorContent = async () => {
      if (editor && localStep.markdown) {
        const blocksFromMarkdown = await editor.tryParseMarkdownToBlocks(
          localStep.markdown
        );
        editor.replaceBlocks(editor.document, blocksFromMarkdown);
      }
    };

    updateEditorContent();
  }, [editor, localStep.markdown]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
      doneEdit();
    } else if (event.key === "Escape") {
      cancelEdit();
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    autoGrow(event.currentTarget);
  };

  const replaceVariables = (content: string) => {
    return content.replace(/\{\{(.*?)\}\}/g, (match, varName) => {
      const variable = variables[varName.trim()];
      console.error(match + " " + varName + " " + "Not found in vars");
      if (
        variable &&
        variable.value !== undefined &&
        String(variable.value).trim().length
      ) {
        return `<span style="color: black; font-weight: bold;">${String(variable.value).trim()}</span>`;
      } else {
        return `<span style="color: red; font-weight: bold;">[${varName.trim()} not set]</span>`;
      }
    });
  };

  const renderMarkdown = (content: string) => {
    const contentWithVariables = replaceVariables(content);
    return { __html: marked(contentWithVariables) };
  };

  const enableEditMode = (event: React.MouseEvent<HTMLSpanElement>) => {
    const element = event.target as HTMLElement;
    if (element.tagName === "A") {
      event.preventDefault();
      return;
    }
    setLocalStep({ ...localStep, mode: ModeEnum.EDITING });
  };

  const doneEdit = () => {
    updateStep(localStep);
  };

  const doDelete = () => {
    deleteStep(step);
  };

  const cancelEdit = () => {
    if (localStep.instruction.trim() === "") {
      deleteStep(step);
    } else {
      setLocalStep({ ...step, mode: ModeEnum.OUTPUT });
    }
  };

  const autoGrow = (element: HTMLTextAreaElement) => {
    if (element && element.scrollHeight > element.clientHeight) {
      element.style.height = `${element.scrollHeight}px`;
      element.style.width = "100%";
    }
  };

  const { theme } = useTheme();

  const renderContent = () => {
    switch (localStep.mode) {
      case ModeEnum.EDITING:
        return (
          <div className="w-full">
            <div className="flex grid grid-cols-6 items-top gap-4">
              <div
                onClick={enableEditMode}
                className="flex-col items-center p-3 bg-gray-100 cursor-pointer border col-span-5"
              >
                <Textarea
                  ref={textareaRef}
                  value={localStep.instruction}
                  onChange={(e) =>
                    setLocalStep({
                      ...localStep,
                      instruction: (e.currentTarget as HTMLTextAreaElement)
                        .value,
                    })
                  }
                  placeholder="Enter your AI instructions and hit the play button to generate your output."
                  className="w-full focus:ring-slate-800 focus:border-slate-800 bg-white"
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyUp}
                />
                <div className="flex justify-end text-xs mt-2 space-x-2">
                  <div className="p-3">
                    Enter your AI instructions and hit the play button to
                    generate your output.
                  </div>
                </div>
              </div>
              <div className="space-x-2 col-span-1 align-top">
                <Button onClick={doneEdit} className="px-2 py-1">
                  <PlayIcon className="w-4 h-4" />
                </Button>
                <Button className="px-2 py-1">
                  <TrashIcon onClick={doDelete} className="w-4 h-4" />
                </Button>
                <Button
                  variant="secondary"
                  onClick={cancelEdit}
                  className="px-2 py-1"
                >
                  <XIcon className="w-4 h-4" />
                </Button>
              </div>
            </div>
            {localStep.error && (
              <div className="text-xs text-red-500">{localStep.error}</div>
            )}
          </div>
        );
      case ModeEnum.OUTPUT:
        return (
          <div className="w-full">
            <div className="grid grid-cols-6 items-top gap-4">
              <div
                onClick={enableEditMode}
                className="flex items-top p-3 bg-gray-100 cursor-pointer border col-span-5"
              >
                <SparklesIcon className="min-w-4 min-h-4 mr-2 text-slate-800" />
                <div
                  dangerouslySetInnerHTML={renderMarkdown(
                    localStep.instruction
                  )}
                  className="flex-grow"
                />
              </div>
              <div className="space-x-2 col-span-1"></div>
            </div>
            {!localStep.mute && localStep.markdown && (
              <div className="grid grid-cols-6 items-top gap-4">
                <div className="flex items-top p-3 col-span-5">
                  {editor && (
                    <BlockNoteView
                      theme={theme === "system" ? undefined : theme}
                      editor={editor}
                      editable={false}
                    />
                  )}
                </div>
                <div className="space-x-2 col-span-1"></div>
              </div>
            )}
            {localStep.error && (
              <div className="text-xs text-red-500">{localStep.error}</div>
            )}
          </div>
        );
      case ModeEnum.PROCESSING:
        return (
          <div className="w-full">
            <span className="flex text-sm pt-3 pb-2 mb-2 bg-gray-200 w-full">
              <SparklesIcon className="min-w-4 h-4 mx-2 mt-1 text-hero-600" />
              <div
                dangerouslySetInnerHTML={renderMarkdown(localStep.instruction)}
              ></div>
            </span>
            <div className="grid grid-cols-6 items-top gap-4">
              <div className="flex items-top p-3 col-span-5 flex-col">
                {localStep.markdown && editor && (
                  <BlockNoteView
                    theme={theme === "system" ? undefined : theme}
                    editor={editor}
                    editable={false}
                  />
                )}
                <div className="animate-pulse pt-2 mt-2 space-y-4">
                  <div className="h-4 bg-gray-300 rounded w-full"></div>
                  <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                </div>
              </div>
              <div className="space-x-2 col-span-1"></div>
            </div>
          </div>
        );
      case ModeEnum.IMPROVE:
      case ModeEnum.IMPROVING:
        return (
          <div className="w-full">
            <span className="flex text-sm pt-3 pb-2 mb-2 bg-gray-200 w-full">
              <BoltIcon className="min-w-4 h-4 mx-2 mt-1 text-hero-600" />
              <div className="flex">
                Working on your feedback `{localStep.feedback}` for the
                instruction: `{localStep.instruction}`
              </div>
            </span>
            <div className="animate-pulse pt-2 mt-2 space-y-4">
              <div className="h-4 bg-gray-300 rounded w-full"></div>
              <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="step px-2 mb-2">
      <div className="text-sm font-semibold mb-2">STEP</div>
      {renderContent()}
    </div>
  );
};
