import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";

export const SingleUpload = () => {
  const navigate = useNavigate();
  const { project_id, filename } = useParams();

  const logout = async () => {
    console.log("Logout");
    try {
      await localStorage.removeItem("token");
      await localStorage.clear();
    } catch (error: any) {
      console.error("Error clearing local storage:", error);
    }
    navigate("/");
  };

  useEffect(() => {
    const fetchUploadUrl = async () => {
      try {
        const response = await fetchWithProgress(
          `/api/v1/projects/${project_id}/files/uploads/${filename}`,
          {
            redirect: "manual", // prevent fetch from following the redirect automatically
          },
          navigate
        );

        if (response.status === 301 || response.status === 302) {
          const redirectUrl = response.headers.get("Location");
          if (redirectUrl) {
            navigate(redirectUrl);
          } else {
            console.error("Failed to get redirect URL");
          }
        } else {
          throw new Error("HTTP error! status: " + response.status);
        }
      } catch (error: any) {
        console.error("Error fetching upload URL:", error);
        if (error.message === "HTTP error! status: 403") {
          console.error(
            "Access forbidden: Failed to upload file due to insufficient permissions."
          );
          logout();
        } else if (error.message === "HTTP error! status: 404") {
          console.error("Not found: Failed to upload file.");
          navigate("/404");
        } else {
          console.error("Failed to fetch upload URL");
        }
      }
    };

    fetchUploadUrl();
  }, [project_id, filename, navigate]);

  return <div>Redirecting...</div>;
};
