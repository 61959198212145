import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Stage } from "@/types";

interface RunSettingsProps {
  stage: Stage;
  setStage: (stage: Stage) => void;
}

const RunSettings: React.FC<RunSettingsProps> = ({ stage, setStage }) => {
  const handleStageToggle = (checked: boolean) => {
    setStage(checked ? Stage.LIVE : Stage.TEST);
  };

  return (
    <div className="flex items-center space-x-2">
      Mode:&nbsp;
      <Switch
        id="stage-mode"
        checked={stage === Stage.LIVE}
        onCheckedChange={handleStageToggle}
      />
      <Label htmlFor="stage-mode">
        {stage === Stage.LIVE ? "Live" : "Test"}
      </Label>
    </div>
  );
};

export default RunSettings;
