import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { PlusIcon } from "lucide-react";
import { Separator } from "@/components/ui/separator";

interface InterCellActionsProps {
  onAddInstruction: () => void;
  onAddNote: () => void;
}

export const InterCellActions: React.FC<InterCellActionsProps> = ({
  onAddInstruction,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="py-2 text-center"
      onMouseOver={() => setIsVisible(true)}
      onMouseOut={() => setIsVisible(false)}
    >
      {isVisible && (
        <div className="h-7 inline-flex items-center gap-2 bg-white">
          <Button
            variant="ghost"
            onClick={onAddInstruction}
            className="flex items-center gap-2"
          >
            <PlusIcon className="w-3 h-3" />
            New Step
          </Button>
          {/* <Button variant="ghost" onClick={onAddHeading} className="flex items-center gap-2">
                        <PlusIcon className="w-3 h-3" />
                        <TypeIcon className="w-4 h-4" />
                        Heading
                    </Button>
                    <Button variant="ghost" onClick={onAddNote} className="flex items-center gap-2">
                        <PlusIcon className="w-3 h-3" />
                        <FileIcon className="w-4 h-4" />
                        Note
                    </Button> */}
        </div>
      )}
      {!isVisible && (
        <div className="h-7 flex justify-center">
          <Separator className="mt-4" />
        </div>
      )}
    </div>
  );
};
