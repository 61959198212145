import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "lucide-react";
import { cn } from "@/lib/utils";

interface BackNavProps extends React.HTMLAttributes<HTMLElement> {
  parent: string;
}

export function BackNav({ className, parent, ...props }: BackNavProps) {
  const project_id = localStorage.getItem("project_id");

  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6", className)}
      {...props}
    >
      <Link
        to={`/projects/${project_id}/${parent}`}
        className="bg-background hover:bg-gray-100 py-2 px-4 rounded flex items-center"
      >
        <ArrowLeftIcon className="h-4 w-4 mr-2" />
        Back
      </Link>
    </nav>
  );
}
