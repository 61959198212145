export const AIHero = () => {
  return (
    <div className="ml-5 z-20 flex items-center text-lg font-medium aiherologo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 30"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="mr-2 h-6 w-6"
      >
        <path d="M12 2l10 20H2L12 2z" />
        <line x1="2" y1="28" x2="22" y2="28" />
      </svg>
      A.I. Hero, Inc.
    </div>
  );
};
