import { useState, useEffect, useCallback } from "react";
import { AIHero } from "@/components/common/ai-hero";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import { BackNav } from "@/components/common/back-nav";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { useParams, useNavigate } from "react-router-dom";
import { Chatbot, Inputs, Conversation } from "@/types";
import {
  Loader2Icon,
  CopyIcon,
  TrashIcon,
  CogIcon,
  MessageSquareIcon,
  PlusIcon,
} from "lucide-react";
import { ChatbotInputs } from "@/components/pages/single_chatbot/components/inputs";
import PersonaDropdown from "@/components/pages/single_chatbot/components/persona-dropdown";
import { useTheme } from "@/components/common/theme-provider";
import RunSettings from "@/components/pages/single_chatbot/components/run-settings";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/shadcn";
import "@blocknote/shadcn/style.css";
import { Input } from "@/components/ui/input";
import { Stage } from "@/types";
import MarkdownContent from "./components/markdowncontent";

const SingleChatbot = () => {
  const [chatbot, setChatbot] = useState<Chatbot | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);
  const editor = useCreateBlockNote();
  const [, setElapsedTime] = useState(0);
  const [currentMessage, setCurrentMessage] = useState("");
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>();
  const { project_id, chatbot_id } = useParams<{
    project_id: string;
    chatbot_id: string;
  }>();
  const navigate = useNavigate();
  const { theme } = useTheme();

  useEffect(() => {
    fetchChatbot();
    fetchConversations();
  }, [chatbot_id]);

  useEffect(() => {
    console.log("Selected conversation updated:", selectedConversation);
  }, [selectedConversation]);

  useEffect(() => {
    if (conversations.length > 0 && !selectedConversation) {
      setSelectedConversation(conversations[0]);
    }
  }, [conversations]);

  useEffect(() => {
    if (chatbot?.instructions) {
      const parse = async () => {
        const blocksFromMarkdown = await editor.tryParseMarkdownToBlocks(
          chatbot.instructions
        );
        editor.replaceBlocks(editor.document, blocksFromMarkdown);
      };
      parse();
    }
  }, [chatbot, editor]);

  const fetchChatbot = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/chatbots/chatbots/${chatbot_id}`,
        { method: "GET" },
        navigate
      );

      const data: Chatbot = await response.json();
      setChatbot(data);
    } catch (error: any) {
      console.error("Failed to load the chatbot:", error);
      setError("Failed to load the chatbot. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [project_id, chatbot_id, navigate]);

  const fetchConversations = useCallback(async () => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/chatbots/chatbots/${chatbot_id}/conversations`,
        { method: "GET" },
        navigate
      );

      const data: { conversations: Conversation[] } = await response.json();
      setConversations(data.conversations);
      if (data.conversations.length > 0) {
        setSelectedConversation(data.conversations[0]);
      }
    } catch (error: any) {
      console.error("Failed to load conversations:", error);
      setError("Failed to load conversations. Please try again.");
    }
  }, [project_id, chatbot_id, navigate]);

  const fetchConversation = useCallback(
    async (conversationId: string, silent: boolean = false) => {
      console.log("Fetching conversation:", conversationId);
      try {
        const response = await fetchWithProgress(
          `/api/v1/projects/${project_id}/chatbots/chatbots/${chatbot_id}/conversations/${conversationId}`,
          { method: "GET" },
          navigate,
          silent
        );

        const updatedConversation: Conversation = await response.json();
        if (
          !selectedConversation ||
          selectedConversation.conversation_id !== conversationId
        ) {
          setSelectedConversation(updatedConversation);
        }
        setConversations((prevConversations) =>
          prevConversations.map((conv) =>
            conv.conversation_id === updatedConversation.conversation_id
              ? updatedConversation
              : conv
          )
        );
        return updatedConversation;
      } catch (error: any) {
        console.error("Failed to fetch conversation:", error);
        setError("Failed to fetch conversation. Please try again.");
        return null;
      }
    },
    [
      project_id,
      chatbot_id,
      navigate,
      setSelectedConversation,
      setConversations,
    ]
  );

  const monitorConversation = useCallback(
    async (conversationId: string) => {
      try {
        const updatedConversation = await fetchConversation(
          conversationId,
          true
        );

        if (!updatedConversation) return;

        console.log(
          "Monitoring conversation: ",
          conversationId,
          updatedConversation.status
        );

        if (updatedConversation.current_time && updatedConversation.start_at) {
          setElapsedTime(
            Math.round(
              (new Date(updatedConversation.current_time).getTime() -
                new Date(updatedConversation.start_at).getTime()) /
                1000
            ) || 0
          );
        }

        if (["responding", "pending"].includes(updatedConversation.status)) {
          setTimeout(() => monitorConversation(conversationId), 100);
        }
      } catch (error: any) {
        console.error("Failed to monitor conversation:", error);
      }
    },
    [fetchConversation]
  );

  const selectConversation = useCallback(
    (conversation: Conversation) => {
      fetchConversations().then(() => {
        monitorConversation(conversation.conversation_id);
      });
    },
    [fetchConversation, monitorConversation]
  );

  const createNewConversation = useCallback(() => {
    // Clear the selected conversation instead of creating a new one
    setSelectedConversation(null);
  }, []);

  const sendMessage = useCallback(
    async (message: string) => {
      try {
        let conversationId: string;
        let newConversation: Conversation | null = null;
        if (!selectedConversation) {
          // Create a new conversation if there's no selected conversation
          const response = await fetchWithProgress(
            `/api/v1/projects/${project_id}/chatbots/chatbots/${chatbot_id}/conversations`,
            {
              method: "POST",
              body: JSON.stringify({ title: message, description: "" }),
            },
            navigate
          );
          newConversation = await response.json();
          await fetchConversations();
          if (newConversation) {
            conversationId = newConversation.conversation_id;
          }
        } else {
          conversationId = selectedConversation.conversation_id;
        }
        setTimeout(async () => {
          await fetchWithProgress(
            `/api/v1/projects/${project_id}/chatbots/chatbots/${chatbot_id}/conversations/${conversationId}/messages`,
            {
              method: "POST",
              body: JSON.stringify({ role: "user", content: message }),
            },
            navigate
          );
          if (newConversation) {
            console.log("Sending message to conversation:", conversationId);
            selectConversation(newConversation);
          } else {
            monitorConversation(conversationId);
          }
        }, 10);
      } catch (error: any) {
        console.error("Failed to send message:", error);
        setError("Failed to send message. Please try again.");
      }
    },
    [
      project_id,
      chatbot_id,
      navigate,
      selectedConversation,
      fetchConversations,
      monitorConversation,
    ]
  );

  const handleSendMessage = useCallback(async () => {
    if (!currentMessage.trim()) return;
    await sendMessage(currentMessage);
    setCurrentMessage("");
  }, [currentMessage, sendMessage]);

  const cloneChatbot = async () => {
    if (!chatbot) return;
    try {
      setSelectedConversation(null);
      const newChatbotData = JSON.parse(JSON.stringify(chatbot));
      delete newChatbotData.project_id;
      delete newChatbotData.chatbot_id;
      delete newChatbotData._id;
      delete newChatbotData.status;

      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/chatbots/chatbots`,
        {
          method: "POST",
          body: JSON.stringify(newChatbotData),
        },
        navigate
      );
      const newChatbot = await response.json();
      navigate(`/projects/${project_id}/chatbots/${newChatbot.chatbot_id}`);
    } catch (error: any) {
      console.error("Failed to clone chatbot", error);
    }
  };

  const archiveChatbot = async () => {
    if (!chatbot) return;
    try {
      await fetchWithProgress(
        `/api/v1/projects/${project_id}/chatbots/chatbots/${chatbot.chatbot_id}`,
        {
          method: "DELETE",
        },
        navigate
      );
      navigate(`/projects/${project_id}/chatbots`);
    } catch (error: any) {
      console.error("Failed to archive chatbot", error);
      if (error.response && error.response.status >= 500) {
        console.error(
          "Server Error:",
          error.response.status,
          error.response.text
        );
        navigate("/5xx");
      }
    }
  };

  const updateChatbot = useCallback(async () => {
    if (!chatbot) return;
    try {
      const instructions = await editor.blocksToMarkdownLossy(editor.document);
      const updatedChatbot = { ...chatbot, instructions };
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/chatbots/chatbots/${chatbot.chatbot_id}`,
        {
          method: "POST",
          body: JSON.stringify(updatedChatbot),
        },
        navigate
      );

      const data = await response.json();
      setChatbot(data);
      cancel();
    } catch (error: any) {
      console.error("Failed to update the chatbot:", error);
      setError("Failed to update the chatbot. Please try again.");
    }
  }, [chatbot, editor, project_id, navigate]);

  const handlePersonaSelect = useCallback(
    async (personaId: string) => {
      if (!chatbot) return;
      setChatbot({ ...chatbot, persona_id: personaId });
    },
    [chatbot]
  );

  const updateStage = useCallback(
    async (stage: Stage) => {
      if (!chatbot) return;
      setChatbot({ ...chatbot, stage });
    },
    [chatbot]
  );

  const updateChatbotInputs = useCallback(
    (newInputs: Inputs) => {
      if (!chatbot) return;
      setChatbot({ ...chatbot, background_knowledge: newInputs });
    },
    [chatbot]
  );

  const cancel = useCallback(() => {
    setIsEditing(false);
    fetchChatbot();
  }, [fetchChatbot]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2Icon className="mr-4 animate-spin" /> Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        Error: {error}
      </div>
    );
  }

  if (!chatbot) {
    return (
      <div className="flex items-center justify-center h-screen">
        No chatbot found
      </div>
    );
  }

  const TypingIndicator = () => (
    <div className="flex items-center space-x-2 p-2 bg-gray-200 rounded-full shadow-sm min-h-[30px] max-w-[60px] justify-center">
      <div className="flex space-x-1">
        <div className="w-2 h-2 bg-gray-500 rounded-full animate-pulse"></div>
        <div
          className="w-2 h-2 bg-gray-500 rounded-full animate-pulse"
          style={{ animationDelay: "0.2s" }}
        ></div>
        <div
          className="w-2 h-2 bg-gray-500 rounded-full animate-pulse"
          style={{ animationDelay: "0.4s" }}
        ></div>
      </div>
    </div>
  );

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <BackNav className="mx-6" parent="chatbots" />
        </nav>
        <ResponsiveNav />
        <div className="flex-grow flex justify-center items-center space-x-1">
          <Button
            onClick={() => {
              cloneChatbot();
            }}
            variant="secondary"
            className="bg-white border-none shadow-none"
          >
            <CopyIcon className="w-4 h-4" />
          </Button>
          <Button
            onClick={() => {
              archiveChatbot();
            }}
            variant="secondary"
            className="bg-white border-none shadow-none"
          >
            <TrashIcon className="w-4 h-4" />
          </Button>
          <Button
            onClick={() => setIsEditing(!isEditing)}
            variant="secondary"
            className="bg-white border-none shadow-none"
          >
            {isEditing ? (
              <MessageSquareIcon className="w-4 h-4" />
            ) : (
              <CogIcon className="w-4 h-4" />
            )}
          </Button>
        </div>
        <div className="ml-auto flex items-center space-x-4">
          {selectedConversation && selectedConversation.status && (
            <Badge variant="outline">{selectedConversation.status}</Badge>
          )}
          <UserNav />
        </div>
      </header>

      <main className="flex flex-col flex-grow overflow-x-hidden px-4 grid grid-cols-8 min-h-[90vh]">
        {isEditing ? (
          <>
            <>
              <div className="col-span-1 p-4"></div>
              <div className="col-span-3 border-r p-4 space-y-4">
                <div className="space-y-4">
                  <ChatbotInputs
                    inputs={chatbot.background_knowledge || {}}
                    updateInputs={updateChatbotInputs}
                    projectId={project_id || ""}
                  />
                </div>
                <div className="space-y-4">
                  <h1 className="text-sm font-semibold">SETTINGS</h1>
                  <RunSettings stage={chatbot.stage} setStage={updateStage} />
                </div>
                <hr />
                <div className="space-y-4">
                  <h1 className="text-sm font-semibold">ASSUME PERSONA</h1>
                  <PersonaDropdown
                    onSelectPersona={handlePersonaSelect}
                    persona_id={chatbot.persona_id}
                  />
                </div>
                <hr />
              </div>
              <div className="col-span-3 p-4 space-y-4">
                <div className="space-y-4">
                  <h1 className="text-sm font-semibold">INSTRUCTIONS</h1>
                  <div className="text-sm text-gray-500">
                    Please write instructions for the chatbot here.
                  </div>
                  <div className="">
                    <BlockNoteView
                      theme={theme === "system" ? undefined : theme}
                      editor={editor}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1 p-4"></div>
            </>
            <>
              <div className="col-span-8 flex justify-center">
                <Button onClick={updateChatbot} className="mx-2">
                  Save
                </Button>
                <Button onClick={cancel} className="mx-2">
                  Cancel
                </Button>
              </div>
            </>
          </>
        ) : (
          <>
            <div className="col-span-2 p-4 border-r">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Conversations</h2>
                <Button onClick={createNewConversation} size="sm">
                  <PlusIcon className="w-4 h-4 mr-2" />
                  New
                </Button>
              </div>
              <ul className="space-y-2">
                {conversations.length === 0 && "No conversations found."}
                {conversations.map((conversation) => (
                  <li key={conversation.conversation_id}>
                    <Button
                      variant={
                        selectedConversation?.conversation_id ===
                        conversation.conversation_id
                          ? "default"
                          : "ghost"
                      }
                      className="w-full justify-start"
                      onClick={() => selectConversation(conversation)}
                    >
                      {conversation.title || `Untitled`}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-span-5 p-4 flex flex-col h-full">
              <div className="flex-grow overflow-y-auto mb-4">
                {selectedConversation?.messages.map((message, index) => (
                  <div
                    key={index}
                    className={`mb-4 ${
                      message.role === "user" ? "text-right" : "text-left"
                    }`}
                  >
                    <div
                      className={`inline-block p-2 ${
                        message.role === "user"
                          ? "border-r-4 border-slate-800"
                          : "border-l-4 border-primary"
                      }`}
                    >
                      <MarkdownContent markdown={message.content} />
                    </div>
                  </div>
                ))}
                {(selectedConversation?.status === "pending" ||
                  selectedConversation?.status === "responding") && (
                  <div className="text-left mb-4">
                    <TypingIndicator />
                  </div>
                )}
              </div>
              <div className="flex">
                <Input
                  type="text"
                  value={currentMessage}
                  onChange={(e) => setCurrentMessage(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                  className="flex-grow mr-2 p-2 border rounded "
                  placeholder="Type your message here..."
                  disabled={selectedConversation?.status === "responding"}
                />
                <Button
                  onClick={handleSendMessage}
                  disabled={selectedConversation?.status === "responding"}
                >
                  Send
                </Button>
              </div>
            </div>
            <div className="col-span-1 p-4"></div>
          </>
        )}
      </main>
    </>
  );
};

export default SingleChatbot;
