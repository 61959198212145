import { MainNav } from "@/components/common/main-nav";
import { AIHero } from "@/components/common/ai-hero";
import { UserNav } from "@/components/common/user-nav";

export const ServerError = () => {
  return (
    <>
      <div className="hidden flex-col md:flex">
        <header className="border-b">
          <div className="flex h-16 items-center px-4">
            <AIHero />
            <MainNav className="mx-6" />
            <div className="ml-auto flex items-center space-x-4">
              <UserNav />
            </div>
          </div>
        </header>
        <main className="text-left flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
          <div className="items-center justify-between mx-auto grid w-full max-w-6xl gap-2">
            <h1 className="text-3xl font-semibold">
              500 - Internal Server Error
            </h1>
            <p>
              There was an error on the server. Please try again later or
              contact support if the issue persists.
            </p>
          </div>
        </main>
      </div>
    </>
  );
};
