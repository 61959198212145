import { useState, useEffect } from "react";
import type { MouseEvent as ReactMouseEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AIHero } from "@/components/common/ai-hero";
import { MainNav } from "@/components/common/main-nav";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import { Pin, PinOff } from "lucide-react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { MoreHorizontal, PlusCircle } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { Chatbot, ChatbotTemplate } from "@/types";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { KindEnum } from "@/types";
import { TrashIcon } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";

const Chatbots = () => {
  const { project_id } = useParams<{ project_id: string }>();
  const [chatbots, setChatbots] = useState<Chatbot[]>([]);
  const [isFetchingChatbots, setIsFetchingChatbots] = useState(false);
  const navigate = useNavigate();

  const itemsPerPage = 6;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { toast } = useToast();

  const [toBeDeleted, setToBeDeleted] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [newChatbotName, setNewChatbotName] = useState("");
  const [newChatbotPurpose, setNewChatbotPurpose] = useState("");

  useEffect(() => {
    fetchChatbots();
  }, [page]);

  const fetchChatbots = async () => {
    try {
      setIsFetchingChatbots(true);
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/chatbots/chatbots?limit=${itemsPerPage}&skip=${
          (page - 1) * itemsPerPage || 0
        }`,
        { method: "GET" },
        navigate
      );

      if (response) {
        const data = await response.json();
        const fetchedChatbots = data.chatbots || [];
        setTotalPages(data.pages);
        setChatbots(fetchedChatbots);
        setIsFetchingChatbots(false);
      }
    } catch (error: any) {
      setIsFetchingChatbots(false);
      console.error("Failed to fetch chatbots", error);
    }
  };
  const openConfirmDeleteDialog = (toBeDeleted: string) => {
    setToBeDeleted(toBeDeleted);
    setIsCreateDialogOpen(true);
  };

  const handlePin = async (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    chatbotId: string
  ) => {
    event.stopPropagation();
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/chatbots/chatbots/${chatbotId}/settings`,
        {
          method: "POST",
          body: JSON.stringify({ pinned: true }),
        },
        navigate
      );

      if (response) {
        fetchChatbots(); // Refresh the list after pinning
      }
    } catch (error: any) {
      console.error("Failed to pin chatbot", error);
    }
  };

  const handleUnpin = async (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    chatbotId: string
  ) => {
    event.stopPropagation();
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/chatbots/chatbots/${chatbotId}/settings`,
        {
          method: "POST",
          body: JSON.stringify({ pinned: false }),
        },
        navigate
      );

      if (response) {
        fetchChatbots(); // Refresh the list after unpinning
      }
    } catch (error: any) {
      console.error("Failed to unpin chatbot", error);
    }
  };

  const handleDelete = async (toBeDeleted: string) => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/chatbots/chatbots/${toBeDeleted}`,
        {
          method: "DELETE",
        },
        navigate
      );

      if (response) {
        setIsCreateDialogOpen(false);
        fetchChatbots(); // Refresh the list after deletion
        toast({
          description: (
            <div className="flex flex-row items-center">
              <TrashIcon className="mr-3 h-4 w-4" />
              Chatbot deleted.
            </div>
          ),
        });
      }
    } catch (error: any) {
      toast({
        description: (
          <div className="flex flex-row items-center">
            <TrashIcon className="mr-3 h-4 w-4" />
            {error}
          </div>
        ),
      });
      console.error("Failed to delete chatbot", error);
    }
  };

  const handleChatbotClick = (chatbotId: string) => {
    navigate(
      `/projects/${localStorage.getItem("project_id")}/chatbots/${chatbotId}`
    );
  };

  const addChatbot = async (chatbotTemplate: ChatbotTemplate) => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/chatbots/chatbots`,
        {
          method: "POST",
          body: JSON.stringify(chatbotTemplate),
        },
        navigate
      );

      if (response) {
        const newChatbot: Chatbot = await response.json();

        await fetchWithProgress(
          `/api/v1/projects/${project_id}/chatbots/chatbots/${newChatbot.chatbot_id}`,
          {
            method: "POST",
            body: JSON.stringify(newChatbot),
          },
          navigate
        );

        navigate(`/projects/${project_id}/chatbots/${newChatbot.chatbot_id}`);
      }
    } catch (error: any) {
      console.error("Failed to create chatbot", error);
      toast({
        description: "Failed to create chatbot. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleCreateChatbot = () => {
    if (newChatbotName.trim() === "" || newChatbotPurpose.trim() === "") {
      toast({
        description:
          "Please provide both a name and description for the chatbot.",
        variant: "destructive",
      });
      return;
    }

    addChatbot({
      kind: KindEnum.V1,
      category: "General",
      name: newChatbotName,
      purpose: newChatbotPurpose,
    } as ChatbotTemplate);

    setIsCreateDialogOpen(false);
    setNewChatbotName("");
    setNewChatbotPurpose("");
  };

  const renderPagination = () => (
    <div className="flex justify-center mt-4 items-center">
      <Button
        className="w-24"
        onClick={() => setPage((p) => Math.max(1, p - 1))}
        disabled={page === 1}
      >
        Previous
      </Button>
      <span className="mx-4">
        Page {page} of {totalPages}
      </span>
      <Button
        className="w-24"
        onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
        disabled={page === totalPages}
      >
        Next
      </Button>
    </div>
  );

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <MainNav />
        </nav>
        <ResponsiveNav />
        <div>
          <UserNav />
        </div>
      </header>

      <main className="container space-y-7 p-6">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-semibold">Chatbots</h1>
          <Button onClick={() => setIsCreateDialogOpen(true)}>
            <PlusCircle className="mr-2 h-4 w-4" /> Create New Chatbot
          </Button>
        </div>

        <div className="mx-auto w-full">
          <ScrollArea className="min-h-[550px] mb-2">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {isFetchingChatbots ? (
                // Skeleton loader for grid
                Array(6)
                  .fill(null)
                  .map((_, index) => (
                    <Card key={index} className="animate-pulse">
                      <CardHeader>
                        <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
                        <div className="h-4 bg-gray-300 rounded w-1/2"></div>
                      </CardHeader>
                      <CardContent className="min-h-24">
                        <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
                        <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                      </CardContent>
                      <CardFooter>
                        <div className="h-8 bg-gray-300 rounded w-1/5"></div>
                      </CardFooter>
                    </Card>
                  ))
              ) : chatbots.length === 0 ? (
                <Card className="text-center p-6">
                  <CardContent className="min-h-32">
                    <p className="text-xl mb-4">No chatbots found</p>
                    <Button onClick={() => setIsCreateDialogOpen(true)}>
                      <PlusCircle className="mr-2 h-4 w-4" /> Create Your First
                      Chatbot
                    </Button>
                  </CardContent>
                </Card>
              ) : (
                chatbots.map((chatbot) => (
                  <Card
                    key={chatbot.chatbot_id}
                    className="cursor-pointer"
                    onClick={() => handleChatbotClick(chatbot.chatbot_id)}
                  >
                    <CardHeader>
                      <CardTitle>{chatbot.name}</CardTitle>
                      <CardDescription>
                        {/* <Badge variant="outline">{chatbot.status}</Badge> */}
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="min-h-32">
                      <p className="text-sm text-gray-600 mb-2">
                        {new Date(
                          chatbot.updated_at || chatbot.created_at
                        ).toLocaleString()}
                      </p>
                      <p className="line-clamp-3">{chatbot.purpose}</p>
                    </CardContent>
                    <CardFooter className="flex justify-between">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          chatbot.pinned
                            ? handleUnpin(e, chatbot.chatbot_id)
                            : handlePin(e, chatbot.chatbot_id);
                        }}
                      >
                        {chatbot.pinned ? (
                          <Pin className="h-4 w-4 text-primary" />
                        ) : (
                          <PinOff className="h-4 w-4 text-black" />
                        )}
                      </Button>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <MoreHorizontal className="h-4 w-4" />
                            <span className="sr-only">Open menu</span>
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>Actions</DropdownMenuLabel>
                          <DropdownMenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              openConfirmDeleteDialog(chatbot.chatbot_id);
                            }}
                          >
                            Delete
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </CardFooter>
                  </Card>
                ))
              )}
            </div>
          </ScrollArea>
          {totalPages > 1 && renderPagination()}
        </div>
      </main>

      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure you want to proceed?</DialogTitle>
            <DialogDescription>This action cannot be undone.</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="destructive"
              type="button"
              onClick={() => handleDelete(toBeDeleted)}
            >
              Confirm
            </Button>
            <Button
              variant="outline"
              type="button"
              onClick={() => setIsDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isCreateDialogOpen} onOpenChange={setIsCreateDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create New Chatbot</DialogTitle>
            <DialogDescription>
              Enter the details for your new chatbot.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Name
              </Label>
              <Input
                id="name"
                value={newChatbotName}
                placeholder="My Chatbot"
                onChange={(e) => setNewChatbotName(e.target.value)}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="purpose" className="text-right">
                Purpose
              </Label>
              <Textarea
                id="purpose"
                value={newChatbotPurpose}
                placeholder="A brief purpose of your chatbot, i.e. what it does."
                onChange={(e) => setNewChatbotPurpose(e.target.value)}
                className="col-span-3"
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              type="submit"
              onClick={handleCreateChatbot}
              disabled={!newChatbotName.trim() || !newChatbotPurpose.trim()}
            >
              Create Chatbot
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Chatbots;
