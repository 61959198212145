import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { fetchEventSource } from "@microsoft/fetch-event-source";

let fetchCount = 0;

const startProgress = (silent: boolean) => {
  if (silent || fetchCount++ > 0) return;
  NProgress.start();
};

const stopProgress = (silent: boolean) => {
  if (silent || --fetchCount > 0) return;
  fetchCount = 0;
  NProgress.done();
};

type NavigateFunction = (path: string, options?: { replace: boolean }) => void;

const handleAuthorizationError = async (
  response: Response,
  navigate: NavigateFunction
): Promise<never> => {
  const errorMap: Record<number, { message: string; path: string }> = {
    402: { message: "You are out of credits.", path: "/402" },
    403: { message: "Not authorized to view the workflow", path: "/" },
    404: { message: "Not found", path: "/404" },
    500: { message: "Server Error", path: "/5xx" },
  };

  const { status } = response;
  const error = errorMap[status];

  if (error) {
    console.error(error.message);
    if (status === 403) {
      try {
        await Promise.all([
          localStorage.removeItem("token"),
          localStorage.clear(),
        ]);
      } catch (err) {
        console.error("Error clearing local storage:", err);
      }
    }
    navigate(error.path, { replace: false });
  } else if (status >= 400) {
    throw new Error(`HTTP error! status: ${status}`);
  }

  throw new Error(`Unhandled error: ${status}`);
};

export const fetchWithProgress = async (
  input: RequestInfo,
  init: RequestInit,
  navigate: NavigateFunction,
  silent: boolean = false,
  authenticated: boolean = true
): Promise<Response> => {
  startProgress(silent);

  try {
    const headers = new Headers(init?.headers);
    if (authenticated) {
      const token = localStorage.getItem("token");
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("Content-Type", "application/json");
    }

    const response = await fetch(input, { ...init, headers });

    if (!response.ok) {
      await handleAuthorizationError(response, navigate);
    }

    return response;
  } finally {
    stopProgress(silent);
  }
};


export const fetchWithStreaming = async (
  input: string,
  onMessage: (message: string) => void,
  silent: boolean = false,
  authenticated: boolean = true
): Promise<void> => {
  // Start progress indicator if not in silent mode
  startProgress(silent);

  try {
    // Prepare headers for the request
    const headers: Record<string, string> = {
      "Content-Type": "application/json", // Default content type
    };

    // Add Authorization header if authenticated
    if (authenticated) {
      const token = localStorage.getItem("token");
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }
    }

    // Perform the fetch with EventSource
    await fetchEventSource(input, {
      method: "GET", // Adjust method as needed, e.g., "POST" for POST requests
      headers,
      onmessage: (event) => {
        if (event.data) {
            onMessage(event.data);
        } else {
            console.log("Received empty event data");
        }
    },
     
      openWhenHidden: true, // Optional: Keep the connection open even when the tab is hidden
    });
  } catch (error: any) {
    // Log any unexpected errors during the request
    console.error("Failed to fetch streaming data:", error);
  } finally {
    // Stop progress indicator if not in silent mode
    stopProgress(silent);
  }
};

