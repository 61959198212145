import { useState, useEffect } from "react";
import { Avatar } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { PersonIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { ModeToggle } from "@/components/common/theme-toggle";

export function UserNav() {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const me = localStorage.getItem("me");
    if (me) {
      try {
        const userData = JSON.parse(me);
        setUserEmail(userData.email || "");
      } catch (error: any) {
        console.error("Error parsing user data from localStorage:", error);
      }
    }
  }, []);

  const logout = async () => {
    console.log("Logout");
    try {
      await localStorage.removeItem("token");
      await localStorage.clear();
    } catch (error: any) {
      console.error("Error clearing local storage:", error);
    }
    navigate("/");
  };

  return (
    <div className="flex items-center mr-5">
      <div className="mr-3">
        <ModeToggle />
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="relative h-8 w-8 p-1 rounded-full">
            <Avatar className="h-6 w-6">
              <PersonIcon className="h-6 w-6" />
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end" forceMount>
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-xs leading-none text-muted-foreground">
                {userEmail}
              </p>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => logout()}>Logout</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
