import { useState, useEffect } from "react";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useNavigate } from "react-router-dom";
import { Usage as UsageCls, Credits } from "@/types";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";

export const Usage = () => {
  // @ts-ignore
  const [project, setProject] = useState(
    JSON.parse(localStorage.getItem("project") || "{}")
  );
  const [usageObj, setUsageObj] = useState<UsageCls | null>(null);
  const [creditsObj, setCreditsObj] = useState<Credits | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const production = { completion_tokens: 0.0375, prompt_tokens: 0.0125 };
  const development = { completion_tokens: 0.00375, prompt_tokens: 0.00125 };

  useEffect(() => {
    fetchUsage();
  }, []);

  const fetchUsage = async () => {
    setIsLoading(true);
    try {
      console.log("Loading usage", project.project_id);

      const usageResponse = await fetchWithProgress(
        `/api/v1/projects/${project.project_id}/usage`,
        { method: "GET" },
        navigate
      );

      if (usageResponse) {
        const usageData = await usageResponse.json();
        setUsageObj(usageData);
      }

      const creditsResponse = await fetchWithProgress(
        `/api/v1/projects/${project.project_id}/credits`,
        { method: "GET" },
        navigate
      );

      if (creditsResponse) {
        const creditsData = await creditsResponse.json();
        setCreditsObj(creditsData);
      }
    } catch (error: any) {
      console.error("Failed to load usage:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="m-2 p-4">
      {project ? (
        <>
          <h1 className="capitalize">{project.name} / Usage</h1>
          {isLoading ? (
            <div className="w-full flex flex-col mt-5">
              <div className="animate-pulse h-10 bg-gray-300 rounded w-full my-5"></div>
              <div className="animate-pulse my-5">
                <div className="h-4 my-2 bg-gray-300 rounded w-3/4"></div>
                <div className="h-4 my-2 bg-gray-300 rounded w-3/4"></div>
                <div className="h-4 my-2 bg-gray-300 rounded w-3/4"></div>
              </div>
            </div>
          ) : usageObj ? (
            <div className="container mx-auto px-4 py-8">
              <Card className="mb-8 shadow-none border-none">
                <CardHeader>
                  <CardTitle>Total Usage and Pricing</CardTitle>
                </CardHeader>
                <CardContent>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>Run Time:</strong>
                        </TableCell>
                        <TableCell>
                          {usageObj.total_usage.run_time.toFixed(2)} seconds
                        </TableCell>
                        <TableCell>
                          <strong>Input Tokens:</strong>
                        </TableCell>
                        <TableCell>
                          {(usageObj.total_usage.input_tokens / 1000).toFixed(
                            2
                          )}{" "}
                          K
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Successful Runs:</strong>
                        </TableCell>
                        <TableCell>{usageObj.total_usage.success}</TableCell>
                        <TableCell>
                          <strong>Output Tokens:</strong>
                        </TableCell>
                        <TableCell>
                          {(usageObj.total_usage.output_tokens / 1000).toFixed(
                            2
                          )}{" "}
                          K
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Input Charges (to date):</strong>
                        </TableCell>
                        <TableCell>
                          ${usageObj.total_usage.input_charges.toFixed(4)}
                        </TableCell>
                        <TableCell>
                          <strong>Total Credits (to date):</strong>
                        </TableCell>
                        <TableCell>${creditsObj?.credits.toFixed(4)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Output Charges (to date):</strong>
                        </TableCell>
                        <TableCell>
                          ${usageObj.total_usage.output_charges.toFixed(4)}
                        </TableCell>
                        <TableCell>
                          <strong>Total Charges (to date):</strong>
                        </TableCell>
                        <TableCell>
                          $
                          {usageObj.total_usage.input_charges +
                            usageObj.total_usage.output_charges -
                            (creditsObj?.credits || 0) >
                          0
                            ? (
                                usageObj.total_usage.input_charges +
                                usageObj.total_usage.output_charges -
                                (creditsObj?.credits || 0)
                              ).toFixed(4)
                            : 0.0}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <div className="text-right mt-4">
                    <small>
                      * Development Mode Pricing: Input:{" "}
                      {development.prompt_tokens} per 1K Tokens, Output:{" "}
                      {development.completion_tokens} per 1K Tokens
                    </small>
                    <br />
                    <small>
                      * Production Mode Pricing: Input:{" "}
                      {production.prompt_tokens} per 1K Tokens, Output:{" "}
                      {production.completion_tokens} per 1K Tokens
                    </small>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>Usage this Month</CardTitle>
                </CardHeader>
                <CardContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Date</TableHead>
                        <TableHead>Run Time (s)</TableHead>
                        <TableHead>Input Tokens</TableHead>
                        <TableHead>Output Tokens</TableHead>
                        <TableHead>Input Charges ($)</TableHead>
                        <TableHead>Output Charges ($)</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {usageObj.daily_usage.map((usage, index) => (
                        <TableRow key={index}>
                          <TableCell>{`${usage.year || "2024"}-${String(
                            usage.month
                          ).padStart(2, "0")}-${String(usage.day).padStart(
                            2,
                            "0"
                          )}`}</TableCell>
                          <TableCell>
                            {usage.usage.run_time.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {(usage.usage.input_tokens / 1000).toFixed(2)} K
                          </TableCell>
                          <TableCell>
                            {(usage.usage.output_tokens / 1000).toFixed(2)} K
                          </TableCell>
                          <TableCell>
                            ${usage.usage.input_charges.toFixed(4)}
                          </TableCell>
                          <TableCell>
                            ${usage.usage.output_charges.toFixed(4)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
              <div className="mt-5 text-xs text-gray-400">
                NOTE: These numbers might not be real-time and may not reflect
                final billing.
              </div>
            </div>
          ) : (
            <div>Unable to get usage details.</div>
          )}
        </>
      ) : (
        <div>
          You are not logged in. Please visit this page when you're logged in.
        </div>
      )}
    </div>
  );
};
