import { Link, useLocation } from "react-router-dom";
import { cn } from "@/lib/utils";
import { useMemo } from "react";

export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const project_id = useMemo(() => localStorage.getItem("project_id"), []);
  const { pathname } = useLocation();

  const links = [
    { to: `/projects/${project_id}/home`, label: "Home" },
    { to: `/projects/${project_id}/chatbots`, label: "Chatbots" },
    { to: `/projects/${project_id}/workflows`, label: "Workflows" },
    { to: `/projects/${project_id}/data`, label: "Data" },
    { to: `/personas`, label: "Personas" },
    { to: `/projects/${project_id}/settings`, label: "Settings" },
  ];

  return (
    <nav
      className={cn(
        "flex items-start text-1xl space-y-8 flex-col mt-6 md:mt-0 md:space-y-0 md:space-x-6 md:flex-row md:text-sm md:items-center",
        className
      )}
      {...props}
    >
      {links.map(({ to, label }) => (
        <Link
          key={to}
          to={to}
          className={cn(
            "transition-colors hover:text-primary",
            pathname === to && "font-bold text-primary",
            pathname.startsWith(to) &&
              label === "Personas" &&
              "font-bold text-primary",
            pathname.includes("documents") &&
              label === "Data" &&
              "font-bold text-primary"
          )}
        >
          {label}
        </Link>
      ))}
    </nav>
  );
}
