import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { AIHero } from "@/components/common/ai-hero";
import { MainNav } from "@/components/common/main-nav";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import { useTheme } from "@/components/common/theme-provider";

import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/shadcn";
import "@blocknote/shadcn/style.css";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Document } from "@/types";

import { Loader2Icon } from "lucide-react";

import { fetchWithProgress } from "@/hooks/useFetchWithProgress";

const SingleDocument: React.FC = () => {
  const { document_id } = useParams<{ document_id: string }>();
  const navigate = useNavigate();
  const [document, setDocument] = useState<Document | null>(null);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const editor = useCreateBlockNote();
  const { project_id } = useParams<{
    project_id: string;
  }>();
  const { theme } = useTheme();

  const fetchDocument = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/files/documents/${document_id}`,
        {},
        navigate,
        false,
        true
      );

      if (response) {
        const data = await response.json();
        setDocument(data);
        setName(data.name);
        const blocksFromMarkdown = await editor.tryParseMarkdownToBlocks(
          data.content
        );
        editor.replaceBlocks(editor.document, blocksFromMarkdown);
      }
    } catch (error: any) {
      console.error("Failed to load the document:", error);
    } finally {
      setIsLoading(false);
    }
  }, [document_id, editor, navigate, project_id]);

  useEffect(() => {
    fetchDocument();
  }, [fetchDocument]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const content = (
        await editor.blocksToMarkdownLossy(editor.document)
      ).trim();
      const updatedDocument = {
        ...document,
        name,
        content,
      };

      console.log("updatedDocument:", updatedDocument);
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/files/documents/${document_id}`,
        {
          method: "PUT",
          body: JSON.stringify(updatedDocument),
        },
        navigate,
        false,
        true
      );

      if (response) {
        const data = await response.json();
        setDocument(data);
      }
    } catch (error: any) {
      console.error("Failed to update the document:", error);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <div className="flex flex-row">
            <Loader2Icon className="mr-4 animate-spin" /> Loading...
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <MainNav />
        </nav>
        <ResponsiveNav />
        <div>
          <UserNav />
        </div>
      </header>

      <main className="container space-y-7 p-6">
        <div className="mx-auto w-full">
          <div className="space-y-4 mb-6">
            <Input
              type="text"
              value={name}
              onChange={(e) =>
                setName((e.currentTarget as HTMLInputElement).value)
              }
              placeholder="Document Name"
              className="w-full text-2xl border-none shadow-none font-semibold"
            />
          </div>
          <div className="rounded-lg overflow-hidden">
            <BlockNoteView
              theme={theme === "system" ? undefined : theme}
              editor={editor}
            />
          </div>
          <div className="mt-6">
            <Button onClick={handleSave} disabled={isSaving}>
              {isSaving ? "Saving..." : "Save Changes"}
            </Button>
          </div>
        </div>
      </main>
    </>
  );
};

export default SingleDocument;
