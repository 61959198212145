// File: @/components/pages/home/TemplateCard.tsx

import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { WorkflowTemplate } from "@/types";

interface TemplateCardProps {
  template: WorkflowTemplate;
  onUseTemplate: (template: WorkflowTemplate) => void;
}

export const TemplateCard: React.FC<TemplateCardProps> = ({
  template,
  onUseTemplate,
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{template.name}</CardTitle>
        <CardDescription>{template.category}</CardDescription>
      </CardHeader>
      <CardContent className="min-h-24">
        <p>{template.description}</p>
      </CardContent>
      <CardFooter className="flex justify-center">
        <Button onClick={() => onUseTemplate(template)}>Use Template</Button>
      </CardFooter>
    </Card>
  );
};
