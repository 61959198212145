import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AIHero } from "@/components/common/ai-hero";
import { MainNav } from "@/components/common/main-nav";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import { ArrowLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import {
  KindEnum,
  WorkflowTemplate,
  Workflow,
  NoteStep,
  InstructionStep,
  TypeEnum,
  ModeEnum,
} from "@/types";
import { GenerateWorkflow } from "@/components/pages/home/components/generate_workflow";
import { TemplateCard } from "@/components/pages/home/components/template_card";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";

const Home = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const { project_id } = useParams<{ project_id: string }>();
  const [templates] = useState<WorkflowTemplate[]>([
    {
      kind: KindEnum.V1,
      category: "Finance",
      name: "ESG Performance Analysis",
      task: "Conduct a comprehensive ESG performance analysis for investors",
      description:
        "This workflow analyzes a company's ESG performance based on various reports and generates an in-depth summary for investors",
      inputs: {
        mode: ModeEnum.EXPECTS,
        variables: {
          company_name: {
            type: "string",
            title: "Company Name",
            description: "The name of the company being analyzed",
            value: "Apple Inc.",
          },
          analysis_year: {
            type: "number",
            title: "Analysis Year",
            description: "The year for which the analysis is being conducted",
            value: 2023,
          },
          ticker_symbol: {
            type: "string",
            title: "Ticker Symbol",
            description: "The stock ticker symbol of the company",
            value: "AAPL",
          },
        },
        use_cached: true,
        sources: [],
        description: "Specify the company details for the ESG analysis.",
      },
      steps: [
        {
          type: TypeEnum.NOTE,
          mode: ModeEnum.OUTPUT,
          markdown:
            "Note: This workflow conducts a thorough ESG performance analysis based on provided reports and industry benchmarks. Ensure all required documents are uploaded and variables are set correctly before proceeding.",
          description:
            "Guidance on required inputs for the ESG analysis workflow.",
        } as NoteStep,
        {
          type: TypeEnum.INSTRUCTION,
          mode: ModeEnum.OUTPUT,
          instruction:
            "Create a compelling introduction and executive summary for the ESG analysis of {{company_name}} ({{ticker_symbol}}) for the year {{analysis_year}}.",
          markdown:
            "# ESG Performance Analysis: {{company_name}} ({{ticker_symbol}})\n\n## Executive Summary\n\nThis comprehensive analysis evaluates the Environmental, Social, and Governance (ESG) performance of {{company_name}} for the fiscal year {{analysis_year}}. Our assessment is based on the company's 10-K filing, Environmental Progress Report, Sustainability Report, and industry ESG benchmarks.\n\n[Provide a concise yet impactful overview of {{company_name}}'s ESG performance, highlighting:\n- Key strengths and achievements\n- Areas for improvement\n- Overall ESG rating or score (if applicable)\n- Comparison to industry peers\n- Significant changes from previous years\n- Potential impact on investor decisions]",
          description:
            "Generate an engaging introduction and executive summary for the ESG analysis.",
        } as InstructionStep,
        {
          type: TypeEnum.INSTRUCTION,
          mode: ModeEnum.OUTPUT,
          instruction:
            "Conduct an in-depth analysis of {{company_name}}'s performance in each ESG focus area.",
          markdown:
            "## ESG Focus Areas Analysis\n\n{% for area in ['Environmental', 'Social', 'Governance'] %}\n### {{area}} Performance\n\n#### Key Metrics and Trends\n[Analyze and visualize crucial {{area}} metrics for {{company_name}}, showing year-over-year trends]\n\n#### Industry Benchmark Comparison\n[Compare {{company_name}}'s {{area}} performance against industry standards, highlighting areas of excellence or concern]\n\n#### Notable Initiatives and Achievements\n[Describe significant {{area}}-related projects, innovations, or milestones achieved by {{company_name}} in {{analysis_year}}]\n\n#### Areas for Improvement\n[Identify and explain {{area}} aspects where {{company_name}} could enhance its performance]\n\n#### Risk Assessment\n[Evaluate potential {{area}}-related risks and their possible impact on {{company_name}}'s operations and reputation]\n\n{% endfor %}",
          description:
            "Analyze and summarize the company's performance in each ESG focus area comprehensively.",
        } as InstructionStep,
        {
          type: TypeEnum.INSTRUCTION,
          mode: ModeEnum.OUTPUT,
          instruction:
            "Provide a detailed industry comparison of {{company_name}}'s ESG performance.",
          markdown:
            "## Industry Comparison\n\n### Overall ESG Performance Ranking\n[Present a chart or table showing {{company_name}}'s ESG ranking among industry peers]\n\n### Strengths Relative to Industry\n[Highlight areas where {{company_name}} outperforms industry averages, with supporting data]\n\n### Areas Lagging Behind Industry Standards\n[Identify aspects where {{company_name}} falls short of industry benchmarks, including potential reasons and improvement strategies]\n\n### Emerging Industry Trends\n[Discuss new ESG trends in the industry and {{company_name}}'s position relative to these developments]",
          description:
            "Conduct a comprehensive comparison of the company's ESG performance against industry standards and competitors.",
        } as InstructionStep,
        {
          type: TypeEnum.INSTRUCTION,
          mode: ModeEnum.OUTPUT,
          instruction:
            "Analyze {{company_name}}'s future ESG strategy and goals.",
          markdown:
            "## Future ESG Strategy and Goals\n\n### Short-term Objectives (1-2 years)\n[Outline {{company_name}}'s immediate ESG targets and initiatives]\n\n### Long-term Vision (3-5+ years)\n[Describe {{company_name}}'s long-term ESG ambitions and strategic roadmap]\n\n### Planned Investments and Initiatives\n[Detail significant ESG-related investments or projects {{company_name}} has announced]\n\n### Alignment with Global Standards\n[Discuss how {{company_name}}'s future ESG goals align with international standards like the UN SDGs or Paris Agreement]\n\n### Potential Impact on Business Model\n[Analyze how {{company_name}}'s ESG strategy might influence its operations, products, or services in the coming years]",
          description:
            "Provide an in-depth analysis of the company's future ESG goals and planned initiatives.",
        } as InstructionStep,
        {
          type: TypeEnum.INSTRUCTION,
          mode: ModeEnum.OUTPUT,
          instruction:
            "Conclude the analysis with key takeaways and investment considerations based on {{company_name}}'s ESG performance.",
          markdown:
            "## Conclusion and Investment Considerations\n\n### Key Takeaways\n1. [Summarize the most critical insights from the ESG analysis]\n2. [Highlight {{company_name}}'s ESG trajectory and future outlook]\n3. [Emphasize unique ESG aspects that set {{company_name}} apart in its industry]\n\n### Investment Considerations\n- **ESG Risk Profile**: [Evaluate the overall ESG risk level for {{company_name}}]\n- **Growth Opportunities**: [Identify ESG-related growth potential for {{company_name}}]\n- **Regulatory Landscape**: [Discuss potential ESG regulations that could impact {{company_name}}]\n- **Stakeholder Sentiment**: [Analyze how {{company_name}}'s ESG performance might influence customer, employee, and investor perceptions]\n\n### Final Recommendation\n[Provide a balanced, data-driven recommendation for investors considering {{company_name}}'s stock ({{ticker_symbol}}) from an ESG perspective]",
          description:
            "Conclude the analysis with impactful takeaways and ESG-related considerations for investors.",
        } as InstructionStep,
      ],
    },
    {
      kind: KindEnum.V1,
      category: "Health & Wellness",
      name: "Write a Daily Moment of Zen",
      task: "Write a personalized Daily Moment of Zen",
      description:
        "This workflow generates a tailored daily moment of zen to enhance mindfulness and well-being",
      inputs: {
        mode: ModeEnum.EXPECTS,
        variables: {
          user_name: {
            type: "string",
            title: "Your Name",
            description: "Your name for personalization",
            value: "",
          },
          focus_area: {
            type: "string",
            title: "Focus Area",
            description:
              "Area of life you want to focus on for zen (e.g., work, relationships, personal growth)",
            value: "",
          },
        },
        sources: [],
        use_cached: true,
        description:
          "Specify personal information for a customized daily moment of zen.",
      },
      steps: [
        {
          type: TypeEnum.NOTE,
          mode: ModeEnum.OUTPUT,
          markdown:
            "Note: This workflow generates a personalized daily moment of zen. Please ensure you've specified your name and focus area in the variables on the left before proceeding.",
          description: "Guidance on required inputs for the workflow.",
        } as NoteStep,
        {
          type: TypeEnum.INSTRUCTION,
          mode: ModeEnum.OUTPUT,
          instruction:
            "Create an immersive introduction for {{user_name}}'s daily zen moment, focusing on {{focus_area}}.",
          markdown:
            "# Daily Moment of Zen for {{user_name}}\n\n## Setting the Scene\n\nImagine yourself in a serene space that resonates with your focus on {{focus_area}}. {{user_name}}, as you enter this tranquil realm, feel the weight of the world gently lift from your shoulders. [Vividly describe a peaceful environment tailored to {{user_name}}'s {{focus_area}}, engaging all senses.]",
          description:
            "Create an immersive introduction for the daily moment of zen.",
        } as InstructionStep,
        {
          type: TypeEnum.INSTRUCTION,
          mode: ModeEnum.OUTPUT,
          instruction:
            "Guide {{user_name}} through a targeted mindful breathing exercise related to {{focus_area}}.",
          markdown:
            "## Mindful Breathing: Cultivating {{focus_area}}\n\n{{user_name}}, let's begin with a breathing exercise designed to enhance your {{focus_area}}:\n\n1. Sit comfortably, allowing your spine to be tall yet relaxed.\n2. Close your eyes and take a deep breath in through your nose for 4 counts, imagining {{focus_area}}-related positive energy flowing in.\n3. Hold for 4 counts, letting this energy permeate your being.\n4. Exhale slowly for 6 counts, releasing any tension related to {{focus_area}}.\n5. Repeat this cycle 5 times, each time strengthening your connection to {{focus_area}}.",
          description:
            "Guide the user through a mindful breathing exercise tailored to their focus area.",
        } as InstructionStep,
        {
          type: TypeEnum.INSTRUCTION,
          mode: ModeEnum.OUTPUT,
          instruction:
            "Facilitate a brief but impactful gratitude journaling session for {{user_name}}, centered on {{focus_area}}.",
          markdown:
            "## Gratitude Journaling: Appreciating {{focus_area}}\n\n{{user_name}}, take a moment to reflect on and write down:\n\n1. One small, often overlooked aspect of {{focus_area}} that you're grateful for today.\n2. A challenge in {{focus_area}} that has helped you grow recently.\n3. Someone who has positively influenced your {{focus_area}} – consider expressing your gratitude to them today.\n\n[Provide space for responses]",
          description: "Facilitate a focused gratitude journaling session.",
        } as InstructionStep,
        {
          type: TypeEnum.INSTRUCTION,
          mode: ModeEnum.OUTPUT,
          instruction:
            "Create a powerful guided visualization for {{user_name}}, incorporating elements of {{focus_area}}.",
          markdown:
            "## Guided Visualization: Manifesting Your Ideal {{focus_area}}\n\nClose your eyes, {{user_name}}. Imagine stepping into a future where you've mastered {{focus_area}}.\n\n[Guide a vivid, sensory-rich visualization of {{user_name}}'s ideal scenario in {{focus_area}}, incorporating sights, sounds, feelings, and even scents that represent success and fulfillment in this area.]",
          description:
            "Guide the user through a powerful visualization exercise.",
        } as InstructionStep,
        {
          type: TypeEnum.INSTRUCTION,
          mode: ModeEnum.OUTPUT,
          instruction:
            "Conclude with a personalized affirmation and intention setting for {{user_name}}'s {{focus_area}}.",
          markdown:
            '## Daily Affirmation and Intention for {{focus_area}}\n\n{{user_name}}, embrace this affirmation:\n\n"I am constantly growing and evolving in my {{focus_area}}. Each day brings new opportunities for mastery and joy."\n\nNow, set your intention for today:\n\n"Today, I intend to [encourage {{user_name}} to complete this sentence with a specific, actionable intention related to {{focus_area}}]."\n\nCarry this energy with you throughout your day, {{user_name}}. You\'re making wonderful progress in your {{focus_area}}!',
          description:
            "Provide a concluding affirmation and intention-setting exercise tailored to the user's focus area.",
        } as InstructionStep,
      ],
    },
  ]);

  useEffect(() => {
    // Any initial setup if needed
  }, []);

  const addWorkflow = async (workflowTemplate: WorkflowTemplate) => {
    try {
      console.log("Creating workflow", workflowTemplate);
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows`,
        {
          method: "POST",
          body: JSON.stringify(workflowTemplate),
        },
        navigate
      );

      if (response) {
        const newWorkflow: Workflow = await response.json();
        await fetchWithProgress(
          `/api/v1/projects/${project_id}/autonomous/workflows/${newWorkflow.workflow_id}`,
          {
            method: "POST",
            body: JSON.stringify(newWorkflow),
          },
          navigate
        );

        navigate(
          "/projects/" + project_id + "/workflows/" + newWorkflow.workflow_id
        );
      }
    } catch (error: any) {
      console.error("Failed to create workflow", error);
    }
  };

  const addEmptyWorkflow = () => {
    addWorkflow({
      kind: KindEnum.V1,
      category: "General",
      name: "My New Workflow",
      task: "Create a simple starter workflow",
      description:
        "This is a new workflow to help you get started with AI Hero Studio",
      inputs: {
        variables: {},
        use_cached: true,
        mode: ModeEnum.EXPECTS,
        sources: [],
        description: "This is a simple starter workflow.",
      },
      steps: [
        {
          type: TypeEnum.NOTE,
          mode: ModeEnum.OUTPUT,
          markdown:
            "# Welcome to Your New AI Hero Studio Workflow!\n\nCongratulations on taking the first step towards creating powerful, AI-driven workflows. This starter template is designed to help you understand the basics and spark your creativity.",
          description: "Engaging introduction to the new workflow.",
        } as NoteStep,
        {
          type: TypeEnum.NOTE,
          mode: ModeEnum.OUTPUT,
          markdown:
            "## Getting Started\n\n1. **Explore**: Take a moment to review this workflow's structure.\n2. **Customize**: Modify the steps to suit your specific needs.\n3. **Expand**: Add new steps to create a more complex workflow.\n4. **Test**: Run your workflow to see it in action.\n\nRemember, the key to a great workflow is iterative improvement. Start simple, test often, and refine based on results.\n\nFor more detailed guidance, check out our [comprehensive documentation](https://ai-hero.notion.site/Using-AI-Hero-Studio-bdc873ee9bd443eb8f388aa7f79b14cf).\n\nHappy creating!",
          description: "Guidance on using and customizing the workflow.",
        } as NoteStep,
        {
          type: TypeEnum.INSTRUCTION,
          mode: ModeEnum.OUTPUT,
          instruction:
            "Write a short poem or haiku to express your excitement about this new workflow.",
          markdown: "[Run the workflow to see the output of this step]",
          description: "Creative task to engage users with the workflow.",
        } as InstructionStep,
        {
          type: TypeEnum.NOTE,
          mode: ModeEnum.OUTPUT,
          markdown:
            "## Congratulations!\n\nYou've successfully created a new workflow in AI Hero Studio. Feel free to explore, experiment, and innovate with your workflows to unlock their full potential.",
          description: "Closing message to celebrate the new workflow.",
        },
      ],
    } as WorkflowTemplate);
  };

  const handleReturn = () => {
    setIsVisible(false);
  };

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <MainNav />
        </nav>
        <ResponsiveNav />
        <div>
          <UserNav />
        </div>
      </header>

      <main className="container space-y-7 p-6 mt-10">
        <div className="flex items-center">
          {!isVisible ? (
            <h1 className="text-1xl font-semibold">
              Create a workflow using any of the three options below.
            </h1>
          ) : (
            <Button onClick={handleReturn} variant="ghost">
              <ArrowLeft className="mr-2 h-4 w-4" /> Return
            </Button>
          )}
        </div>

        <div className="flex">
          <GenerateWorkflow
            projectId={project_id!}
            onAddWorkflow={addWorkflow}
            navigate={navigate}
          />

          {!isVisible && (
            <>
              <div className="mx-5">
                <div className="flex flex-col items-center md:mt-2">
                  <div className="flex-grow border-r  md:h-11"></div>
                  <div className="my-5 md:my-3 md:mx-4">OR</div>
                  <div className="flex-grow border-r  md:h-11"></div>
                </div>
              </div>

              <Card className="shadow-none">
                <CardHeader>
                  <CardTitle>Get Started</CardTitle>
                  <CardDescription>
                    Start from scratch or check out our tutorial:
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="flex space-x-4 justify-center">
                    <Button onClick={addEmptyWorkflow}>Empty Workflow</Button>
                    <Button variant="outline" asChild>
                      <a
                        href="https://ai-hero.notion.site/Using-AI-Hero-Studio-bdc873ee9bd443eb8f388aa7f79b14cf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read Our Docs
                      </a>
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </>
          )}
        </div>

        {!isVisible && (
          <>
            <div>
              <h1 className="text-1xl font-semibold">Template Workflows</h1>
              <h2 className="text-sm text-muted-foreground">
                Choose from our pre-made templates:
              </h2>
            </div>

            <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
              {templates.map((template, index) => (
                <TemplateCard
                  key={index}
                  template={template}
                  onUseTemplate={addWorkflow}
                />
              ))}
            </div>
          </>
        )}
      </main>
    </>
  );
};

export default Home;
