import { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom"; // Import navigate function
import { Persona } from "@/types";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress"; // Import the new fetchWithProgress function

const NO_SELECTION = "no_selection";

interface PersonaDropdownProps {
  onSelectPersona: CallableFunction;
  persona_id: string;
}

const PersonaDropdown: React.FC<PersonaDropdownProps> = ({
  onSelectPersona,
  persona_id,
}) => {
  const navigate = useNavigate();
  const [personas, setPersonas] = useState<Persona[]>([]);
  const [selectedPersona, setSelectedPersona] = useState(
    persona_id || NO_SELECTION
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPersonas = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetchWithProgress(
          "/api/v1/me/personas",
          {},
          navigate
        );

        if (response) {
          const data = await response.json();
          const personas = data.personas;
          if (Array.isArray(personas)) {
            setPersonas(personas);
            // If persona_id is provided and exists in the fetched personas, select it
            if (
              persona_id &&
              personas.some((p) => p.persona_id === persona_id)
            ) {
              setSelectedPersona(persona_id);
            }
          } else {
            throw new Error("Received data is not an array");
          }
        }
      } catch (error: any) {
        console.error("Error fetching personas:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPersonas();
  }, [persona_id]);

  useEffect(() => {
    // Update selected persona when persona_id prop changes
    if (persona_id) {
      setSelectedPersona(persona_id);
    } else {
      setSelectedPersona(NO_SELECTION);
    }
  }, [persona_id]);

  const handleSelectChange = (value: string) => {
    setSelectedPersona(value);
    onSelectPersona(value === NO_SELECTION ? null : value);
  };

  if (isLoading) {
    return (
      <div className="flex items-center space-x-2">
        <Loader2 className="h-4 w-4 animate-spin" />
        <span>Loading personas...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  return (
    <Select onValueChange={handleSelectChange} value={selectedPersona}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select a persona" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={NO_SELECTION}>No persona</SelectItem>
        {personas.map((persona) => (
          <SelectItem key={persona.persona_id} value={persona.persona_id}>
            {persona.name}
          </SelectItem>
        ))}
        {personas.length === 0 && (
          <SelectItem value="no_personas" disabled>
            No personas available
          </SelectItem>
        )}
      </SelectContent>
    </Select>
  );
};

export default PersonaDropdown;
