import { useState, useEffect } from "react";
import { AIHero } from "@/components/common/ai-hero";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import { BackNav } from "@/components/common/back-nav";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { useParams, useNavigate } from "react-router-dom";
import {
  Workflow,
  InstructionStep,
  NoteStep,
  ModeEnum,
  Inputs,
  Stage,
} from "@/types";
import { InstructionCell } from "@/components/pages/single_workflow/components/instruction";
import { NotesCell } from "@/components/pages/single_workflow/components/notes";
import { InterCellActions } from "@/components/pages/single_workflow/components/inter-cell-actions";
import { Loader2Icon, CopyIcon, TrashIcon } from "lucide-react";
import { WorkflowInputs } from "@/components/pages/single_workflow/components/inputs";
import PersonaDropdown from "@/components/pages/single_workflow/components/persona-dropdown";
import RunSettings from "@/components/pages/single_workflow/components/run-settings";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";

const SingleWorkflow = () => {
  const [workflow, setWorkflow] = useState<Workflow>();
  const [loading, setLoading] = useState(true);
  const [error] = useState<string>("");
  const [, setElapsedTime] = useState(0);
  const { project_id, workflow_id } = useParams<{
    project_id: string;
    workflow_id: string;
  }>();
  const navigate = useNavigate();

  useEffect(() => {
    fetchWorkflow();
  }, [workflow_id]);

  const fetchWorkflow = async () => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows/${workflow_id}`,
        {
          method: "GET",
        },
        navigate
      );

      const data: Workflow = await response.json();
      setWorkflow(data);
      setLoading(false);
      monitorWorkflowBaseSteps();
    } catch (error: any) {
      console.error("Failed to load the workflow:", error);
      setLoading(false);
    }
  };

  const updateWorkflow = async () => {
    if (!workflow) return;
    try {
      setElapsedTime(0);

      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows/${workflow.workflow_id}`,
        {
          method: "POST",
          body: JSON.stringify(workflow),
        },
        navigate
      );

      const updatedWorkflow = await response.json();
      setWorkflow(updatedWorkflow);
      monitorWorkflowBaseSteps();
    } catch (error: any) {
      console.error("Failed to load the workflow:", error);
    }
  };

  const updateStep = async (index: number, updatedStep: any) => {
    if (!workflow) return;
    try {
      setElapsedTime(0);
      const updatedWorkflow = { ...workflow };
      updatedWorkflow.steps[index] = updatedStep;
      setWorkflow(updatedWorkflow);
      await updateWorkflow();
      monitorWorkflowBaseSteps();
    } catch (error: any) {
      console.error("Failed to load the workflow:", error);
    }
  };

  const cloneWorkflow = async () => {
    if (!workflow) return;
    try {
      const newWorkflowData = JSON.parse(JSON.stringify(workflow));
      delete newWorkflowData.project_id;
      delete newWorkflowData.workflow_id;
      delete newWorkflowData._id;
      delete newWorkflowData.status;
      newWorkflowData.name = `${workflow.name} (Copy)`;

      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows`,
        {
          method: "POST",
          body: JSON.stringify(newWorkflowData),
        },
        navigate
      );

      const newWorkflow = await response.json();
      navigate(`/projects/${project_id}/workflows/${newWorkflow.workflow_id}`);
    } catch (error: any) {
      console.error("Failed to clone workflow", error);
    }
  };

  const archiveWorkflow = async () => {
    if (!workflow) return;
    try {
      await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows/${workflow.workflow_id}`,
        {
          method: "DELETE",
        },
        navigate
      );
      navigate(`/projects/${project_id}/workflows`);
    } catch (error: any) {
      console.error("Failed to archive workflow", error);
      if (error.response && error.response.status >= 500) {
        console.error(
          "Server Error:",
          error.response.status,
          error.response.text
        );
        navigate("/5xx");
      }
    }
  };

  const abort = async () => {
    if (!workflow) return;
    try {
      setElapsedTime(0);

      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows/${workflow.workflow_id}/abort`,
        {
          method: "POST",
        },
        navigate
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error: any) {
      console.error("Failed to load the workflow:", error);
    }
  };

  const runAll = async (step_id: string) => {
    if (!workflow) return;
    if (!step_id) return;
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows/${workflow.workflow_id}/launch`,
        {
          method: "POST",
          body: JSON.stringify({ step_id: step_id }),
        },
        navigate
      );

      if (response.ok) {
        monitorWorkflowBaseSteps();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error: any) {
      console.error("Failed to load the workflow:", error);
    }
  };

  const monitorWorkflowBaseSteps = async () => {
    if (!workflow) return;
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows/${workflow_id}`,
        {
          method: "GET",
        },
        navigate,
        true
      );

      if (response.ok) {
        const data: Workflow = await response.json();
        setWorkflow(data);
        if (!["running", "pending"].includes(data.status)) {
          return;
        }
        if (data.current_time && data.start_at) {
          setElapsedTime(
            Math.round(
              (new Date(data.current_time).getTime() -
                new Date(data.start_at).getTime()) /
                1000
            ) || 0
          );
        }
        setTimeout(() => monitorWorkflowBaseSteps(), 50);
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error: any) {
      console.error("Failed to load the workflow:", error);
    }
  };

  const deleteStep = async (index: number, step_id: string) => {
    if (!workflow) return;
    if (!step_id) {
      // delete using index
      try {
        const newSteps = [...workflow.steps];
        newSteps.splice(index, 1);
        // Update the workflow state with the new steps
        setWorkflow({ ...workflow, steps: newSteps });
      } catch (error: any) {
        console.error("Failed to delete the step:", error);
      }
    } else {
      try {
        workflow.steps = workflow.steps.filter(
          (step) => step.step_id !== step_id
        );
        setTimeout(() => {
          updateWorkflow();
        }, 0);
      } catch (error: any) {
        console.error("Failed to delete the step:", error);
      }
    }
  };

  const handleAddInstruction = async (index: number) => {
    if (!workflow) return;
    const newSteps = [...workflow.steps];
    newSteps.splice(index, 0, {
      type: "instruction",
      instruction: "",
      mode: ModeEnum.EDITING, // Ensure this mode is set
      markdown: "(will be available after the step is run)",
    } as InstructionStep);

    // Update the workflow state with the new steps
    setWorkflow({ ...workflow, steps: newSteps });
  };

  const handleAddNote = async (index: number) => {
    if (!workflow) return;
    const newSteps = [...workflow.steps];
    newSteps.splice(index, 0, {
      type: "note",
      markdown: "",
      mode: ModeEnum.EDITING,
    } as NoteStep);
    workflow.steps = newSteps;
  };

  const handlePersonaSelect = async (personaId: string) => {
    if (!workflow) return;
    workflow.persona_id = personaId;
    await updateWorkflow();
  };

  const updateStage = async (stage: Stage) => {
    if (!workflow) return;
    workflow.stage = stage;
    await updateWorkflow();
  };

  const updateWorkflowInputs = (newInputs: Inputs) => {
    if (!workflow) return;
    workflow.inputs = newInputs;
    updateWorkflow();
  };

  if (loading) {
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <div className="flex flex-row ">
            <Loader2Icon className="mr-4 animate-spin" /> Loading...
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-row ">Error: {error}</div>
      </div>
    );
  }

  if (!workflow) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-row ">No workflow found</div>
      </div>
    );
  }

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <BackNav className="mx-6" parent="workflows" />
        </nav>
        <ResponsiveNav />
        <div className="flex-grow flex justify-center items-center space-x-1">
          <Button
            onClick={() => cloneWorkflow()}
            variant="secondary"
            className="bg-white border-none shadow-none"
          >
            <CopyIcon className="w-4 h-4" />
          </Button>
          <Button
            onClick={() => archiveWorkflow()}
            variant="secondary"
            className="bg-white border-none shadow-none"
          >
            <TrashIcon className="w-4 h-4" />
          </Button>
        </div>
        <div className="ml-auto flex items-center space-x-4">
          <Badge variant="outline">{workflow.status}</Badge>
          {["pending", "running"].includes(workflow.status) ? (
            <Button variant="destructive" onClick={() => abort()}>
              Abort Workflow
            </Button>
          ) : (
            workflow &&
            workflow.steps.length > 0 &&
            workflow.steps[0].step_id && (
              <Button onClick={() => runAll(workflow.steps[0].step_id || "")}>
                Run Workflow
              </Button>
            )
          )}
          <UserNav />
        </div>
      </header>

      <main className="flex flex-grow overflow-x-hidden px-4 grid grid-cols-4">
        <div className="col-span-1 border-r p-4 space-y-4 ">
          <div className="space-y-4 mb-6">
            <h1 className="text-sm font-semibold">PLAN</h1>
            {workflow.steps.map((step, index) => (
              <div key={index} className="grid text-xs">
                {step.type == "instruction" && (
                  <a href={`#${step.step_id}`}>STEP: {step.description}</a>
                )}
                {step.type == "note" && (
                  <a href={`#${step.step_id}`}>NOTE: {step.description}</a>
                )}
              </div>
            ))}
          </div>
          <hr />
          <div className="space-y-4">
            <WorkflowInputs
              inputs={workflow.inputs || {}}
              updateInputs={updateWorkflowInputs}
              projectId={project_id || ""}
            />
          </div>
          <hr />
          <div className="space-y-4">
            <h1 className="text-sm font-semibold">SETTINGS</h1>
            <RunSettings stage={workflow.stage} setStage={updateStage} />
          </div>
          <hr />
          <div className="space-y-4">
            <h1 className="text-sm font-semibold">ASSUME PERSONA</h1>
            <PersonaDropdown
              onSelectPersona={handlePersonaSelect}
              persona_id={workflow.persona_id}
            />
          </div>
          <hr />
        </div>

        <div className="col-span-3 p-4">
          <div className="items-center justify-between grid w-full gap-2 py-5 my-5">
            {workflow.task && (
              <>
                <h3 className="text-sm">{workflow.task}</h3>
              </>
            )}
            <div className="flex text-3xl font-semibold w-full my-2">
              {workflow.name}
            </div>
          </div>
          <div className="w-full">
            <InterCellActions
              onAddInstruction={() => handleAddInstruction(0)}
              onAddNote={() => handleAddNote(0)}
            />
            {workflow.steps.map((step, index) => (
              <div className="mb-2" id={step.step_id} key={index}>
                {step.type === "instruction" ? (
                  <>
                    <InstructionCell
                      step={step as InstructionStep}
                      updateStep={(updatedStep) =>
                        updateStep(index, updatedStep)
                      }
                      deleteStep={(originalStep) =>
                        deleteStep(index, originalStep.step_id || "")
                      }
                      variables={workflow.inputs.variables || {}}
                    />
                    <InterCellActions
                      onAddInstruction={() => handleAddInstruction(index)}
                      onAddNote={() => handleAddNote(index)}
                    />
                  </>
                ) : step.type === "note" ? (
                  <>
                    <NotesCell
                      step={step as NoteStep}
                      updateStep={(updatedStep) =>
                        updateStep(index, updatedStep)
                      }
                      deleteStep={(originalStep) =>
                        deleteStep(index, originalStep.step_id || "")
                      }
                    />
                    <InterCellActions
                      onAddInstruction={() => handleAddInstruction(index + 1)}
                      onAddNote={() => handleAddNote(index + 1)}
                    />
                  </>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </main>
    </>
  );
};

export default SingleWorkflow;
