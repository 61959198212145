import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AIHero } from "@/components/common/ai-hero";
import { MainNav } from "@/components/common/main-nav";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { MoreHorizontal, PlusCircle, TrashIcon } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { Persona } from "@/types";

import { ScrollArea } from "@/components/ui/scroll-area";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";

import { useToast } from "@/components/ui/use-toast";

import { fetchWithProgress } from "@/hooks/useFetchWithProgress"; // Import the new function

const Personas = () => {
  const navigate = useNavigate();
  const [personas, setPersonas] = useState<Persona[]>([]);
  const [isFetchingPersonas, setIsFetchingPersonas] = useState(false);

  const itemsPerPage = 6;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { toast } = useToast();

  const [toBeDeleted, setToBeDeleted] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchPersonas();
  }, [page]);

  const fetchPersonas = async () => {
    setIsFetchingPersonas(true);
    try {
      const response = await fetchWithProgress(
        `/api/v1/me/personas?limit=${itemsPerPage}&skip=${
          (page - 1) * itemsPerPage || 0
        }`,
        {
          method: "GET",
        },
        navigate
      );

      if (response) {
        const data = await response.json();
        const fetchedPersonas = data.personas || [];
        setTotalPages(data.pages);
        setPersonas(fetchedPersonas);
      }
    } catch (error: any) {
      console.error("Failed to load personas:", error);
    } finally {
      setIsFetchingPersonas(false);
    }
  };

  const openConfirmDeleteDialog = (persona_id: string) => {
    setToBeDeleted(persona_id);
    setIsOpen(true);
  };

  const handleDelete = async (toBeDeleted: string) => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/me/personas/${toBeDeleted}`,
        {
          method: "DELETE",
        },
        navigate
      );

      if (response.ok) {
        setIsOpen(false);
        fetchPersonas(); // Refresh the list after deletion
        toast({
          description: (
            <div className="flex flex-row items-center">
              <TrashIcon className="mr-3 h-4 w-4" />
              Persona deleted.
            </div>
          ),
        });
      }
    } catch (error: any) {
      toast({
        description: (
          <div className="flex flex-row items-center">
            <TrashIcon className="mr-3 h-4 w-4" />
            {error}
          </div>
        ),
      });
      console.error("Failed to delete persona", error);
    }
  };

  const handlePersonaClick = (persona_id: string) => {
    navigate(`/personas/${persona_id}`);
  };

  const handleAddPersona = async () => {
    try {
      const newPersonaData = {
        name: "My Persona",
        description: "Description for the new persona",
        content: "I am a professional who loves to automate.",
      };

      const response = await fetchWithProgress(
        "/api/v1/me/personas",
        {
          method: "POST",
          body: JSON.stringify(newPersonaData),
        },
        navigate
      );

      if (response.ok) {
        const data = await response.json();
        const newPersonaId = data.persona_id;
        navigate(`/personas/${newPersonaId}`);
      }
    } catch (error: any) {
      console.error("Failed to create new persona", error);
    }
  };

  const renderPagination = () => (
    <div className="flex justify-center mt-4 items-center">
      <Button
        className="w-24"
        onClick={() => setPage((p) => Math.max(1, p - 1))}
        disabled={page === 1}
      >
        Previous
      </Button>
      <span className="mx-4">
        Page {page} of {totalPages}
      </span>
      <Button
        className="w-24"
        onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
        disabled={page === totalPages}
      >
        Next
      </Button>
    </div>
  );

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <MainNav />
        </nav>
        <ResponsiveNav />
        <div>
          <UserNav />
        </div>
      </header>

      <main className="container space-y-7 p-6">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-semibold">Personas</h1>
          <Button onClick={handleAddPersona}>
            <PlusCircle className="mr-2 h-4 w-4" /> Add New Persona
          </Button>
        </div>

        <div className="mx-auto w-full">
          <ScrollArea className="min-h-[550px] mb-2">
            {isFetchingPersonas ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {Array(6)
                  .fill(null)
                  .map((_, index) => (
                    <Card key={index} className="animate-pulse">
                      <CardHeader>
                        <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
                        <div className="h-4 bg-gray-300 rounded w-1/2"></div>
                      </CardHeader>
                      <CardContent className="min-h-24">
                        <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
                        <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                      </CardContent>
                      <CardFooter>
                        <div className="h-8 bg-gray-300 rounded w-1/4"></div>
                      </CardFooter>
                    </Card>
                  ))}
              </div>
            ) : personas.length === 0 ? (
              <Card className="text-center p-6">
                <CardContent className="min-h-32">
                  <p className="text-xl mb-4">No personas found</p>
                  <Button onClick={handleAddPersona}>
                    <PlusCircle className="mr-2 h-4 w-4" /> Create Your First
                    Persona
                  </Button>
                </CardContent>
              </Card>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {personas.map((persona) => (
                  <Card
                    key={persona.persona_id}
                    className="cursor-pointer"
                    onClick={() => handlePersonaClick(persona.persona_id)}
                  >
                    <CardHeader>
                      <CardTitle>{persona.name}</CardTitle>
                    </CardHeader>
                    <CardContent className="min-h-32">
                      <p className="text-sm text-gray-600 mb-2">
                        {new Date(
                          persona.updated_at || persona.created_at
                        ).toLocaleString()}
                      </p>
                      <p className="line-clamp-3">{persona.description}</p>
                    </CardContent>
                    <CardFooter className="flex justify-between">
                      <div></div>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <MoreHorizontal className="h-4 w-4" />
                            <span className="sr-only">Open menu</span>
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>Actions</DropdownMenuLabel>
                          <DropdownMenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              openConfirmDeleteDialog(persona.persona_id);
                            }}
                          >
                            Delete
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </CardFooter>
                  </Card>
                ))}
              </div>
            )}
          </ScrollArea>
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Are you sure you want to proceed?</DialogTitle>
                <DialogDescription>
                  This action cannot be undone.
                </DialogDescription>
              </DialogHeader>
              <DialogFooter>
                <Button
                  variant="destructive"
                  type="button"
                  onClick={() => handleDelete(toBeDeleted)}
                >
                  Confirm
                </Button>
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          {totalPages > 1 && renderPagination()}
        </div>
      </main>
    </>
  );
};

export default Personas;
