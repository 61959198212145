import React, { useState } from "react";
import { PlusIcon, TrashIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";

import ItemPicker from "./itempicker";
import { useToast } from "@/components/ui/use-toast";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Item, BackgroundKnowledge } from "@/types";

interface InputsComponentProps {
  inputs: BackgroundKnowledge;
  updateInputs: CallableFunction;
  projectId: string;
}

export const ChatbotInputs: React.FC<InputsComponentProps> = ({
  inputs,
  updateInputs,
  projectId,
}) => {
  const [showItemPicker, setShowItemPicker] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState<string>("");

  const { toast } = useToast();

  const deleteInput = (itemId: string) => {
    if (!itemId) return;
    const updatedInputs: BackgroundKnowledge = { ...inputs };
    updatedInputs.sources = updatedInputs.sources.filter(
      (item: Item) => item.item_id !== itemId
    );
    updateInputs(updatedInputs);
    setIsOpen(false);
    toast({
      description: (
        <div className="flex flex-row items-center">
          <TrashIcon className="mr-3 h-4 w-4" />
          Input deleted.
        </div>
      ),
    });
  };

  const handleDeleteInput = (itemId: string) => {
    setIsOpen(true);
    setToBeDeleted(itemId);
  };

  const handleItemsSelected = (
    selectedItems: Item[],
    continueSelection: boolean = false
  ) => {
    const updatedInputs = { ...inputs };
    updatedInputs.sources = [...selectedItems];
    updateInputs(updatedInputs);
    setShowItemPicker(continueSelection);
  };

  const handleUseCachedToggle = (checked: boolean) => {
    const updatedInputs = { ...inputs, use_cached: checked };
    updateInputs(updatedInputs);
  };

  const truncateString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  };

  return (
    <div className="space-y-6">
      <div className="space-y-3">
        <h1 className="text-sm font-semibold">SOURCES</h1>
        {(!inputs.sources || inputs.sources.length === 0) && (
          <div className="text-gray-500 text-sm">No data items added yet.</div>
        )}
        {inputs.sources &&
          inputs.sources.map(
            (item: Item, index: number) =>
              item.item_id && (
                <div
                  key={index}
                  className="flex items-center justify-between p-3 bg-gray-100 rounded mb-2"
                >
                  <div>
                    <span className="font-medium mr-2">
                      {truncateString(item.name, 30)}
                    </span>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => handleDeleteInput(item.item_id || "")}
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                </div>
              )
          )}
        {showItemPicker ? (
          <ItemPicker
            projectId={projectId}
            onSelectItems={handleItemsSelected}
            parentSelectedItems={inputs.sources || []}
          />
        ) : (
          <Button onClick={() => setShowItemPicker(true)}>
            <PlusIcon className="h-4 w-4 mr-2" /> Add Source
          </Button>
        )}
        <hr />
        {inputs.sources && (
          <>
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <Switch
                  id="use-cached"
                  checked={inputs.use_cached || false}
                  onCheckedChange={handleUseCachedToggle}
                />
                <Label htmlFor="use-cached">Use Cached</Label>
              </div>
            </div>
          </>
        )}
        <hr />
      </div>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure you want to proceed?</DialogTitle>
            <DialogDescription>This action cannot be undone.</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="destructive"
              type="button"
              onClick={() => deleteInput(toBeDeleted)}
            >
              Confirm
            </Button>
            <Button
              variant="outline"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChatbotInputs;
