import React, { useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { RefreshCcw, Sparkles } from "lucide-react";
import { WorkflowTemplate } from "@/types";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/shadcn";
import "@blocknote/shadcn/style.css";
import {
  fetchWithProgress,
  fetchWithStreaming,
} from "@/hooks/useFetchWithProgress";

interface GenerateWorkflowProps {
  projectId: string;
  onAddWorkflow: (workflowTemplate: WorkflowTemplate) => Promise<void>;
  navigate: (path: string) => void;
}

export const GenerateWorkflow: React.FC<GenerateWorkflowProps> = ({
  projectId,
  onAddWorkflow,
  navigate,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlanning, setIsPlanning] = useState(false);
  const [workflowPlan, setWorkflowPlan] = useState<WorkflowTemplate | null>(
    null
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const editor = useCreateBlockNote();

  const generate = async () => {
    if (!searchTerm.trim()) return;
    try {
      setIsVisible(true);
      setIsLoading(true);

      // Clear existing blocks
      let accumulatedMarkdown = "";
      await fetchWithStreaming(
        `/api/v1/projects/${projectId}/autonomous/workflows/wizard?task=${encodeURIComponent(
          searchTerm.trim()
        )}`,
        async (message) => {
          const content = JSON.parse(message);
          // Process the streaming data
          accumulatedMarkdown += content;
          // Parse the accumulated markdown to blocks
          const blocksFromMarkdown = await editor.tryParseMarkdownToBlocks(
            accumulatedMarkdown
          );
          editor.replaceBlocks(editor.document, blocksFromMarkdown);
        }
      );
    } catch (error: any) {
      console.error("Failed to generate workflow plan:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const createWorkflow = async () => {
    try {
      setIsPlanning(true);
      const content = (
        await editor.blocksToMarkdownLossy(editor.document)
      ).trim();

      const response = await fetchWithProgress(
        `/api/v1/projects/${projectId}/autonomous/workflows/wizard`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            task: searchTerm.trim(),
            description: content,
          }),
        },
        navigate
      );

      if (response) {
        const plan: WorkflowTemplate = await response.json();
        setWorkflowPlan(plan);
        onAddWorkflow(plan);
      }
    } catch (error: any) {
      console.error("Failed to create workflow:", error);
    } finally {
      setTimeout(() => setIsPlanning(false), 30000); // Wait for 30 seconds
    }
  };

  return (
    <Card className="shadow-none flex-1">
      <CardHeader>
        <CardTitle>Generate Workflow</CardTitle>
        <CardDescription>
          Tell us about your task below to auto-generate a workflow:
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex justify-center mb-4">
          <Input
            className="mr-7"
            ref={inputRef}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="e.g. I want to ..."
            onKeyDown={(e) => e.key === "Enter" && generate()}
          />
          {isVisible ? (
            <Button variant="outline" onClick={generate}>
              <RefreshCcw className="mr-2 h-4 w-4" /> Regenerate
            </Button>
          ) : (
            <Button onClick={generate}>Generate Workflow</Button>
          )}
        </div>

        {isVisible && (
          <>
            <h1 className="text-1xl font-semibold mb-2">Suggested Workflow</h1>

            {isLoading ? (
              <Skeleton className="w-[auto] h-auto rounded p-5 my-4 bg-gray-200 auto-grow">
                <h2 className="text-1xl font-semibold mb-3 flex">
                  <Sparkles className="mr-3 h-4 w-4" />
                  Generating your awesome workflow...
                </h2>
                <BlockNoteView editor={editor} editable={false} />
              </Skeleton>
            ) : isPlanning ? (
              <Skeleton className="w-[auto] h-auto rounded p-5 my-4 bg-gray-200 auto-grow">
                <h2 className="text-1xl font-semibold mb-3 flex">
                  <Sparkles className="mr-3" />
                  AI Hero is initializing your workflow...
                </h2>
              </Skeleton>
            ) : (
              <BlockNoteView editor={editor} editable={true} />
            )}

            <div className="flex justify-end mt-4">
              {!isLoading &&
                !isPlanning &&
                editor.document.length > 0 &&
                !workflowPlan && (
                  <Button onClick={createWorkflow}>Create Workflow</Button>
                )}
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};
