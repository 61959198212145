import { Menu } from "lucide-react";

import { MainNav } from "@/components/common/main-nav";
import { AIHero } from "@/components/common/ai-hero";

import { Button } from "@/components/ui/button";

import {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetTitle,
} from "@/components/ui/sheet";

export function ResponsiveNav({}: React.HTMLAttributes<HTMLElement>) {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size="icon" className="shrink-0 md:hidden">
          <Menu className="h-5 w-5" />
          <span className="sr-only">AI Hero</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left">
        <SheetTitle></SheetTitle>
        <nav>
          <AIHero />
          <MainNav />
        </nav>
      </SheetContent>
    </Sheet>
  );
}
