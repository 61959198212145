import React, { useState, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { marked } from "marked";
import { FileTextIcon, PlayIcon, XIcon, TrashIcon } from "lucide-react";
import { NoteStep, ModeEnum } from "@/types";

interface NotesCellProps {
  step: NoteStep;
  updateStep: (updatedStep: NoteStep) => void;
  deleteStep: (originalStep: NoteStep) => void;
}

marked.use({
  gfm: true,
  breaks: true,
});

export const NotesCell: React.FC<NotesCellProps> = ({
  step,
  updateStep,
  deleteStep,
}) => {
  const [localStep, setLocalStep] = useState<NoteStep>(step);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setLocalStep(step);
  }, [step]);

  useEffect(() => {
    if (localStep.mode === ModeEnum.EDITING) {
      if (textareaRef.current) {
        textareaRef.current.focus();
        autoGrow(textareaRef.current);
      }
    }
  }, [localStep.mode, localStep.step_id]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
      doneEdit();
    } else if (event.key === "Escape") {
      cancelEdit();
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    autoGrow(event.currentTarget);
  };

  const renderMarkdown = (content: string) => {
    return { __html: marked(content) };
  };

  const enableEditMode = (event: React.MouseEvent<HTMLDivElement>) => {
    const element = event.target as HTMLElement;
    if (element.tagName === "A") {
      event.preventDefault();
      return;
    }
    setLocalStep({ ...localStep, mode: ModeEnum.EDITING });
  };

  const doneEdit = () => {
    updateStep(localStep);
  };
  const doDelete = () => {
    deleteStep(step);
  };
  const cancelEdit = () => {
    if (localStep.markdown.trim() === "") {
      deleteStep(step);
    } else {
      setLocalStep({ ...step, mode: ModeEnum.OUTPUT });
    }
  };

  const autoGrow = (element: HTMLTextAreaElement) => {
    if (element && element.scrollHeight > element.clientHeight) {
      element.style.height = `${element.scrollHeight}px`;
      element.style.width = "100%";
    }
  };

  return (
    <div className="step px-2 mb-2">
      <div className="text-sm font-semibold mb-2">NOTE</div>
      {localStep.mode === ModeEnum.EDITING ? (
        <div className="w-full">
          <div className="flex grid grid-cols-6 items-top gap-4">
            <div
              onClick={enableEditMode}
              className="flex-col items-center p-3 bg-gray-100 cursor-pointer border col-span-5"
            >
              <Textarea
                ref={textareaRef}
                value={localStep.markdown}
                onChange={(e) =>
                  setLocalStep({
                    ...localStep,
                    markdown: (e.currentTarget as HTMLTextAreaElement).value,
                  })
                }
                placeholder="Enter context for people who're reading this workflow."
                className="w-full focus:ring-slate-800 focus:border-slate-800 bg-white"
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
              />
              <div className="flex justify-end text-xs mt-2 space-x-2">
                <div className="p-3">
                  Enter context for people who're reading this workflow.
                </div>
              </div>
            </div>
            <div className="space-x-2 col-span-1 align-top">
              <Button onClick={doneEdit} className="px-2 py-1">
                <PlayIcon className="w-4 h-4" />
              </Button>
              <Button onClick={doDelete} className="px-2 py-1">
                <TrashIcon className="w-4 h-4" />
              </Button>
              <Button
                variant="secondary"
                onClick={cancelEdit}
                className="px-2 py-1"
              >
                <XIcon className="w-4 h-4" />
              </Button>
            </div>
          </div>
          {localStep.error && (
            <div className="text-xs text-red-500">{localStep.error}</div>
          )}
        </div>
      ) : localStep.mode === ModeEnum.OUTPUT ? (
        <div className="w-full">
          <div className="flex grid grid-cols-6 items-top gap-4">
            <div
              onClick={enableEditMode}
              className="flex items-top p-3 ursor-pointer border col-span-5"
            >
              <FileTextIcon className="min-w-4 min-h-4 h-4 mr-2 text-slate-800" />
              <div
                dangerouslySetInnerHTML={renderMarkdown(
                  localStep.markdown ||
                    "Enter context for people who're reading this workflow."
                )}
                className="flex-grow text-sm text-slate-600"
              />
            </div>
            <div className="space-x-2 col-span-1"></div>
          </div>
          {localStep.error && (
            <div className="text-xs text-red-500">{localStep.error}</div>
          )}
        </div>
      ) : null}
    </div>
  );
};
